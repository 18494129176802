import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { GetIbeTeamVolumeReport } from "../../services/additonalModules/additionalFeature";
import { exportToExcelNew } from "../../utils/tableExports";
import CommonInput from "../../components/Common/CommonInputComponent";
import { formatDateWithoutTimeStd } from "../../utils/formateDate";
const IbeTeamVolumeReport = () => {
    const { t } = useTranslation();
    const statusColorLookUp = {
        Active: { backgroundColor: "#e4f0f2", color: "#5ba5b8" },
        Verified: { backgroundColor: "#e4f0f2", color: "#5ba5b8" },
        Inactive: { backgroundColor: "#c8cfb7", color: "#89a65f" },
        Terminated: { backgroundColor: "#FEE4D1", color: "#9B5731" },
        "Not Verified": { backgroundColor: "#FEE4D1", color: "#9B5731" },
        Expired: { backgroundColor: "#FEE4D1", color: "#9B5731" },
    };

    const headers = [
        { label: "IBE #", filter: "ibe", filterable: true },
        { label: "IBE NAME", filter: "ibe_name", filterable: true },
        {
            label: "IBE STATUS",
            filter: "ibe_status",
            filterable: true,
            render: (el) => {
                let color = statusColorLookUp[el]?.color;
                let backgroundColor = statusColorLookUp[el]?.backgroundColor;

                return (
                    <div
                        style={{
                            backgroundColor,
                            color,
                            padding: "3px 25px 3px 25px",
                            borderRadius: "10px",
                            fontWeight: "bold",
                        }}
                    >
                        {el}
                    </div>
                );
            },
        },
        { label: "ENTRY LEVEL", filter: "entry_level", filterable: true },
        { label: "PLACEMENT", filter: "placement", filterable: true },
        { label: "BINARY LEVEL", filter: "binary_level", filterable: true },
        { label: "SPONSOR IBE", filter: "sponsor_ibe", filterable: true },
        { label: "SPONSOR NAME", filter: "sponsor_name", filterable: true },
        { label: "RANK", filter: "rank", filterable: true },
        { label: "CITY", filter: "city", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        {
            label: "ORD DATE",
            filter: "ord_date",
            filterable: true,
            type: "date",
        },
        { label: "ORD TYPE", filter: "ord_type", filterable: true },
        { label: "PSV", filter: "psv", filterable: true },
        { label: "LETSV", filter: "letsv", filterable: true },
        { label: "RITSV", filter: "ritsv", filterable: true },
        { label: "CFVL", filter: "cfvl", filterable: true },
        { label: "CFVR", filter: "cfvr", filterable: true },
        { label: "LFTSV", filter: "lftsv", filterable: true },
        { label: "RFTSV", filter: "RFTSV", filterable: true },
        { label: "QCFVL", filter: "qcfvl", filterable: true },
        { label: "QCFVR", filter: "qcfvr", filterable: true },
        { label: "SV TYPE", filter: "sv_type", filterable: true },
    ];

    const startPage = 1;
    const itemsPerPage = 10;
    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [apiCallStatus1, setApiCallStatus1] = useState(false);
    const [filters, setFilters] = useState({});
    const [weekOption, setWeekOptions] = useState([]);
    const [weekNo, setWeekNo] = useState();
    const [selectedWeekNo, setSelectedWeekNo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { data } = GetIbeTeamVolumeReport({
        filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
        itemsPerPage,
        setWeekOptions,
        weekNo,
    });
    // console.log(data?.weekData);
    // console.log(weekNo);

    useEffect(() => {
        if (!weekNo && weekOption.length > 0) {
            // console.log("================");
            setSelectedWeekNo(data?.weekData[0]);
        }
    }, [weekOption]);

    const exportFunc = (data) => {
        exportToExcelNew(data, headers, "ibe-team-volume-report");
    };
    const excelData = GetIbeTeamVolumeReport({
        filters: {},
        apiCallStatus: apiCallStatus1,
        setApiCallStatus: setApiCallStatus1,
        setCurrentPage: () => {},
        setTotalPages: () => {},
        currentPage: 0,
        itemsPerPage: 0,
        isAll: 1,
        setWeekOptions,
        weekNo,
        exportFunc,
    });

    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setApiCallStatus(true);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
        setApiCallStatus(true);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const slider = document.getElementsByClassName("report-table-container")[1];
    console.log(slider);
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    const handleChange = async (value) => {
        data.weekData.map((i) => {
            if (`week ${i.weekNo}` === value) {
                setSelectedWeekNo(i);
            }
        });
    };

    console.log(selectedWeekNo);

    return (
        <>
            <div className="report-Container">
                <div className="report-header"> IBE TEAM VOLUME REPORT</div>
                <div className="report-table-container-flex">
                    <div className="report-table-container overflow-hidden team-volume">
                        <div className="container-fluid">
                            <div className="row my-2 justify-content-lg-start justify-content-end row-gap-2">
                                <div className="col-md-auto col-12 ">
                                    <div className="min-md">
                                    <CommonInput
                                        type="select"
                                        label="Week"
                                        backgroundColor={"fff"}
                                        value={`week ${selectedWeekNo?.weekNo}`}
                                        onChange={(e) => {
                                            handleChange(e.target.value);
                                        }}
                                        options={weekOption}
                                        defaultValue={""}
                                        placeholder={"Select week"}
                                        isValidationEnabled={true}
                                        isLabelDisabled={true}
                                    />
                                    </div>
                                </div>
                                <div className="col-md-auto col-12 px-md-0">
                                <div className="min-md">
                                    <CommonInput
                                        type="text"
                                        label="Week Period"
                                        backgroundColor={"fff"}
                                        value={`${selectedWeekNo?.weekStartDate ? `${selectedWeekNo?.weekStartDate} -` : ""} ${selectedWeekNo?.weekEndDate || ""}`}
                                        defaultValue=""
                                        placeholder={"Select week"}
                                        isValidationEnabled={true}
                                        disabled={true}
                                        isLabelDisabled={true}
                                    />
                                </div>
                                </div>
                                <div
                                    className="col-md-2 col-auto mt-ld-0"
                                    style={{ alignSelf: "center" }}
                                >
                                    <button
                                        className="button-team-volume"
                                        onClick={() => {
                                            setWeekNo(selectedWeekNo.weekNo);
                                            setCurrentPage(1);
                                            setApiCallStatus(true);
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                color: "white",
                                            }}
                                        >
                                            SUBMIT
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={data?.data}
                            filters={filters}
                            firstElementSticky={true}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {data && data?.data?.length !== 0 && (
                            <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                                exportFunc={() => {
                                    setApiCallStatus1(true);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeTeamVolumeReport;

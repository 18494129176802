import { eventService } from "../../services/event/event";
export const EventPurchaseActions = {
  getEvents: async () => {
    try {
      const response = await eventService.getEvents();
      return response.data.data;
    } catch (error) {
      return error.message;
    }
  },

  eventInvoice: async (id) => {
    try {
      const response = await eventService.eventInvoice(id);
      return response.data.data;
    } catch (error) {
      return error.message;
    }
  },
  eventPurchase: async (data) => {
    try {
      const response = await eventService.eventPurchase(data);
      return response.data;
    } catch (error) {
      return error.message;
    }
  },
  getEventReport:async (currentPage, itemsPerPage) => {
    try {
      const response = await eventService.getEventReport(currentPage, itemsPerPage);
      return response.data.data;
    } catch (error) {
      return error.message;
    }
  },

};

import React, { useState } from "react";
import CommonInput from "../Common/CommonInputComponent";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";

const IbeCountrySelection = ({
    formValues,
    registerFields,
    handleFormValueChange,
    setActiveStep,
    courierConfiguration,
    setCourierBaseConfiguration,
    forCustomer = false,
    setPaymentGatewayFeeConf,
}) => {
    const backgroundColor = "#e4f0f2";
    const countryList = (data) => {
        return data.map((item) => item?.name);
    };
    console.log(
        countryList(
            registerFields?.contactInformation?.contactField?.filter(
                (item) => item?.code === "country"
            )[0]?.options ?? [""]
        ),
        formValues?.country,
        "111"
    );
    return (
        <>
            <div className="">
                <div className="common-div-header gta-b">COUNTRY</div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="register-verification-container">
                        <CommonInput
                            type="select"
                            label="Country"
                            backgroundColor={backgroundColor}
                            value={formValues?.country}
                            onChange={(e) => {
                                handleFormValueChange(
                                    e.target.value,
                                    "country"
                                );
                                handleFormValueChange("", "state");
                            }}
                            options={countryList(
                                registerFields?.contactInformation?.contactField?.filter(
                                    (item) => item?.code === "country"
                                )[0]?.options ?? [""]
                            )}
                            defaultValue=""
                        />

                        <button
                            className="common-button"
                            onClick={(e) => {
                                if (formValues?.country) {
                                    setCourierBaseConfiguration(
                                        courierConfiguration[
                                            (formValues?.country).toLowerCase()
                                        ]
                                    );
                                    if (
                                        (formValues?.country).toLowerCase() ===
                                        "india"
                                    ) {
                                        handleFormValueChange(
                                            "91",
                                            "phoneCode"
                                        );
                                        setPaymentGatewayFeeConf({
                                            pgFeePerc:
                                                registerFields?.pgFeePerc,
                                            pgGSTPerc:
                                                registerFields?.pgGSTPerc,
                                        });
                                        setActiveStep(2);
                                    } else if (
                                        (formValues?.country).toLowerCase() ===
                                        "bangladesh"
                                    ) {
                                        handleFormValueChange(
                                            "880",
                                            "phoneCode"
                                        );
                                        setPaymentGatewayFeeConf({
                                            pgFeePerc: 0,
                                            pgGSTPerc: 0,
                                        });
                                        if (forCustomer) {
                                            setActiveStep(2);
                                        } else {
                                            setActiveStep(3);
                                        }
                                    } else {
                                        handleFormValueChange("1", "phoneCode");
                                        setPaymentGatewayFeeConf({
                                            pgFeePerc: 0,
                                            pgGSTPerc: 0,
                                        });
                                        if (forCustomer) {
                                            setActiveStep(2);
                                        } else {
                                            setActiveStep(3);
                                        }
                                    }
                                }
                            }}
                        >
                            SUBMIT
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeCountrySelection;

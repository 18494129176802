import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
const DeCustomerReport = () => {
    const { t } = useTranslation();
    const headers = [
        { label: "IBE #", filter: "ibe", filterable: true },
        { label: "IBE NAME", filter: "ibe_name", filterable: true },
        { label: "JOIN DATE", filter: "join_date", filterable: true },
        { label: "LIVE RANK", filter: "live_rank", filterable: true },
        { label: "PHONE", filter: "phone", filterable: true },
        { label: "EMAIL", filter: "email", filterable: true },
        { label: "CITY", filter: "city", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        { label: "STATUS", filter: "status", filterable: true },
    ];

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const datas = [
        {
            ibe: "105342",
            ibe_name: "MOHAMMAD",
            join_date: "6/26/2024, 9:24 AM",
            live_rank: "Member",
            phone: "+91 9876543210",
            email: "Name@Gmail.com",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            status: "Active",
        },
        {
            ibe: "105342",
            ibe_name: "MOHAMMAD",
            join_date: "6/26/2024, 9:24 AM",
            live_rank: "Member",
            phone: "+91 9876543210",
            email: "Name@Gmail.com",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            status: "Active",
        },
        {
            ibe: "105342",
            ibe_name: "MOHAMMAD",
            join_date: "6/26/2024, 9:24 AM",
            live_rank: "Member",
            phone: "+91 9876543210",
            email: "Name@Gmail.com",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            status: "Active",
        },
        {
            ibe: "105342",
            ibe_name: "MOHAMMAD",
            join_date: "6/26/2024, 9:24 AM",
            live_rank: "Member",
            phone: "+91 9876543210",
            email: "Name@Gmail.com",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            status: "Expired",
        },
        {
            ibe: "105342",
            ibe_name: "MOHAMMAD",
            join_date: "6/26/2024, 9:24 AM",
            live_rank: "Member",
            phone: "+91 9876543210",
            email: "Name@Gmail.com",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            status: "Expired",
        },
        {
            ibe: "105342",
            ibe_name: "MOHAMMAD",
            join_date: "6/26/2024, 9:24 AM",
            live_rank: "Member",
            phone: "+91 9876543210",
            email: "Name@Gmail.com",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            status: "Active",
        },
        {
            ibe: "105342",
            ibe_name: "MOHAMMAD",
            join_date: "6/26/2024, 9:24 AM",
            live_rank: "Member",
            phone: "+91 9876543210",
            email: "Name@Gmail.com",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            status: "Expired",
        },
        {
            ibe: "105342",
            ibe_name: "MOHAMMAD",
            join_date: "6/26/2024, 9:24 AM",
            live_rank: "Member",
            phone: "+91 9876543210",
            email: "Name@Gmail.com",
            city: "GHATAKPUKUR",
            state: "Haryana",
            country: "India",
            status: "Terminated",
        },
    ];
    const startPage = 1;
    const itemsPerPage = 10;
    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }
    return (
        <>
            <div className="report-Container">
                <div className="report-header">
                    {" "}
                    DIRECT ENROLMENT CUSTOMER REPORT
                </div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={datas}
                            filters={filters}
                            firstElementSticky={true}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {datas && datas?.length !== 0 && (
                            <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeCustomerReport;

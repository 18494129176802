import React from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";

const CheckoutPackage = ({
    items,
    totalAmount,
    handleNext,
    shippingCharge = 0,
    conversionFactor,
    gst,
    isPaymentGatewayEnabled,
    pgFeePerc,
    pgGSTPerc,
    isLoading,
    handleRemoveCartItem,
    handleQuantityChange,
    addCartMutation,
    decrementCartMutation,
    removeCartMutation,
}) => {
    const params = useParams();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    // const addCartMutation = ApiHook.CallAddToCart();
    // const decrementCartMutation = ApiHook.CallDecrementCartItem();
    // const removeCartMutation = ApiHook.CallRemoveCartItem();

    // // if (items?.length === 0) {
    // //     navigate("/shopping");
    // // }

    // const handleQuantityChange = (event, packageId) => {
    //     if (event.target.id === "plus") {
    //         addCartMutation.mutate(
    //             { packageId: packageId },
    //             {
    //                 onSuccess: (res) => {
    //                     if (res.status) {
    //                         queryClient.invalidateQueries({
    //                             queryKey: ["cart-items"],
    //                         });
    //                     }
    //                 },
    //             }
    //         );
    //     } else if (event.target.id === "minus") {
    //         decrementCartMutation.mutate(
    //             { packageId: packageId },
    //             {
    //                 onSuccess: (res) => {
    //                     if (res.status) {
    //                         queryClient.invalidateQueries({
    //                             queryKey: ["cart-items"],
    //                         });
    //                     } else {
    //                         queryClient.invalidateQueries({
    //                             queryKey: ["cart-items"],
    //                         });
    //                     }
    //                 },
    //             }
    //         );
    //     }
    // };

    // const handleRemoveCartItem = (packageId) => {
    //     removeCartMutation.mutate(
    //         { packageId: packageId },
    //         {
    //             onSuccess: (res) => {
    //                 if (res.status) {
    //                     queryClient.invalidateQueries({
    //                         queryKey: ["cart-items"],
    //                     });
    //                     if (items?.data?.length === 0) {
    //                         navigate("/shopping");
    //                     }
    //                 }
    //             },
    //         }
    //     );
    // };
    return (
        <>
            <div className="cart-checkout">
                <div className="cart-checkout-table">
                    <div className="cart-table">
                        <div className="cart-table-header">
                            <div className="cart-table-header-cell cell-flex-3 ">
                                PRODUCTS
                            </div>
                            <div className="cart-table-header-cell ">PRICE</div>
                            <div className="cart-table-header-cell ">QTY</div>
                            {/* <div className="cart-table-header-cell ">
                                NET AMT
                            </div>
                            <div className="cart-table-header-cell ">GST</div> */}
                            <div className="cart-table-header-cell ">AMT</div>
                            <div className="cart-table-header-cell ">
                                REMOVE
                            </div>
                        </div>
                        {items?.length > 0 &&
                            items?.map((item) => (
                                <div className="cart-table-body">
                                    {/* <div className="cart-table-body-cell">
                                        {item?.code}
                                    </div> */}
                                    <div className="cart-table-body-cell cell-flex-3 ">
                                        <div style={{ display: "flex" }}>
                                            <img
                                                src={item?.image}
                                                alt=""
                                                style={{
                                                    minWidth: "100px",
                                                    height: "100px",
                                                    borderRadius: "10px",
                                                    marginRight: "20px",
                                                    objectFit: "contain",
                                                    aspectRatio: "2 / 2",
                                                }}
                                            />
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <article className="product_name_article">
                                                    {item?.name}
                                                </article>
                                                <article>
                                                    Item Code: {item?.code}
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item.price,
                                            conversionFactor?.value
                                        )}`}
                                    </div>
                                    <div className="cart-table-body-cell">
                                        <div className="cart-cell-qty-up">
                                            <div
                                                id="minus"
                                                disabled={
                                                    decrementCartMutation.status ===
                                                    "loading"
                                                }
                                                onClick={(e) => {
                                                    handleQuantityChange(
                                                        e,
                                                        item?.packageId
                                                    );
                                                }}
                                            >
                                                -
                                            </div>
                                            <span>{item?.quantity}</span>
                                            <div
                                                id="plus"
                                                disabled={
                                                    addCartMutation.status ===
                                                    "loading"
                                                }
                                                onClick={(e) => {
                                                    handleQuantityChange(
                                                        e,
                                                        item?.packageId
                                                    );
                                                }}
                                            >
                                                +
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item.price * item.quantity,
                                            conversionFactor?.value
                                        )}`}
                                    </div>
                                    {/* <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item.price * gst * item.quantity,
                                            conversionFactor?.value
                                        )}`}
                                    </div> */}
                                    {/* <div className="cart-table-body-cell">
                                        {`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            item.price *
                                                (1 + gst) *
                                                item.quantity,
                                            conversionFactor?.value
                                        )}`}
                                    </div> */}
                                    <div
                                        className="cart-table-body-cell"
                                        style={{ alignItems: "center" }}
                                    >
                                        <button
                                            className="cart-table-remove-btn"
                                            onClick={() => {
                                                handleRemoveCartItem(
                                                    item?.packageId
                                                );
                                            }}
                                        >
                                            <i
                                                className="fa-solid fa-xmark"
                                                style={{ color: "#8c5331" }}
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className="cart-checkout-total-container">
                    <div className="cart-checkout-total-section">
                        <div>SUB TOTAL</div>
                        <div className="cart-checkout-total-section-price">
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                Number(totalAmount),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                    <div className="cart-checkout-total-section">
                        <div>SHIPPING</div>
                        <div className="cart-checkout-total-section-price">
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                Number(shippingCharge),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                    {isPaymentGatewayEnabled && (
                        <div className="cart-checkout-total-section">
                            <div>PAYMENT GATEWAY FEE</div>
                            <div className="cart-checkout-total-section-price">
                                {" "}
                                {`${
                                    conversionFactor.symbolLeft
                                } ${CurrencyConverter(
                                    (Number(totalAmount) +
                                        Number(shippingCharge)) *
                                        pgFeePerc *
                                        (1 + pgGSTPerc),
                                    conversionFactor?.value
                                )}`}
                            </div>
                        </div>
                    )}
                    <div className="cart-checkout-total-section">
                        <div>TOTAL</div>
                        <div className="cart-checkout-total-section-price">
                            {" "}
                            {`${
                                conversionFactor.symbolLeft
                            } ${CurrencyConverter(
                                (Number(totalAmount) + Number(shippingCharge)) *
                                    (1 + pgFeePerc * (1 + pgGSTPerc)),
                                conversionFactor?.value
                            )}`}
                        </div>
                    </div>
                </div>

                <div className="cart-checkout-button-container">
                    <button
                        id="addMoreBtn"
                        onClick={() => {
                            navigate(
                                `/shopping/${params?.type}/${params?.courier}`
                            );
                        }}
                        className="addMoreBtn common-button-gta"
                    >
                        CONTINUE SHOPPING
                    </button>
                    <button className="common-button-gta" onClick={handleNext} disabled={isLoading}>
                        {!isLoading ? "PROCEED CHECKOUT" : "SUBMITTING ..."}
                    </button>
                </div>
            </div>
        </>
    );
};

export default CheckoutPackage;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";
import Skeleton from "react-loading-skeleton";

const UserProfileAvatar = ({ profile, moduleStatus, userKyc }) => {
    const { t } = useTranslation();
    const progressBarRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    //------------------------------------ API ---------------------------------
    const updateAvatarMutation = ApiHook.CallUpdateProfilePicture();
    const deleteProfileMutation = ApiHook.CallDeleteProfileAvatar();

    const handleImageChange = (event) => {
        event.preventDefault(); // Prevent default behavior
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            updateAvatarMutation.mutate(selectedFile, {
                onSuccess: (res) => {
                    if (res.status) {
                        // Clear the value of the file input
                        const fileInput = document.getElementById("fileInput");
                        if (fileInput) {
                            fileInput.value = null;
                        }
                    }
                },
            });
        }
    };

    const deleteProfilePicture = () => {
        deleteProfileMutation.mutate();
    };

    return (
        <>
            <div className="profile-avathar-update">
                <img
                    className="rounded-circle profile_image"
                    src={
                        profile?.avatar
                            ? profile?.avatar
                            : "/images/user-profile.png"
                    }
                    alt=""
                />

                <div className="profile-update-button-group">
                    {profile?.avatar && (
                        <button onClick={deleteProfilePicture}>
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    )}
                    <button
                        onClick={() =>
                            document.getElementById("fileInput").click()
                        }
                    >
                        <i className="fa-regular fa-pen-to-square"></i>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                        />
                    </button>
                </div>
            </div>
            {!profile?.avatar && (
                <a
                    className="updateAvatarLink"
                    onClick={() => {
                        document.getElementById("fileInput").click();
                    }}
                >
                    Update Profile Photo Here!!
                </a>
            )}
        </>
    );
};

export default UserProfileAvatar;

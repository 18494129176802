import React from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const UpgradeCheckoutPackage = ({
  totalAmount,
  handleNext,
  shippingCharge = 0,
  setActiveStepPr,
  setCartData,
  setTotalAmount,
  setTotalPv,
  cartData,
  conversionFactor,
  totalPv,
  upfsvCriteria,
  gst,
  handleGstCalc,
  totalShippingCharge,
  isPaymentGatewayEnabled,
  pgFeePerc,
  pgGSTPerc,
}) => {
  const { t } = useTranslation();
  const handleQuantityChange = (product, type) => {
    const { id: packageId, mrp, upfsv, weight } = product;

    if (type === "plus") {
      setCartData((cart) => ({
        ...cart,
        [packageId]: {
          ...product,
          qty: cart[packageId] ? cart[packageId]?.qty + 1 : 1,
        },
      }));
      setTotalAmount((total) => total + Number(mrp));
      setTotalPv((total) => total + Number(upfsv));
    } else if (
      type === "minus" &&
      cartData[packageId] &&
      cartData[packageId]?.qty > 0
    ) {
      if (cartData[packageId]?.qty === 1) {
        let tempCart = cartData;
        delete tempCart[packageId];
        setCartData(tempCart);
        handleGstCalc();
      } else {
        setCartData((cart) => ({
          ...cart,
          [packageId]: {
            ...product,
            qty: cart[packageId] ? cart[packageId]?.qty - 1 : 0,
          },
        }));
      }

      let [cumulativeTotalAmount, cumulativeTotalPv] = [
        totalAmount - Number(mrp),
        totalPv - Number(upfsv),
      ];
      setTotalAmount(cumulativeTotalAmount);
      setTotalPv(cumulativeTotalPv);
    } else if (type === "remove") {
      let [cumulativeTotalAmount, cumulativeTotalPv] = [
        totalAmount - Number(mrp) * cartData[packageId]?.qty,
        totalPv - Number(upfsv) * cartData[packageId]?.qty,
      ];
      setTotalAmount(cumulativeTotalAmount);
      setTotalPv(cumulativeTotalPv);
      let tempCart = cartData;
      delete tempCart[packageId];
      setCartData(tempCart);
    }
    handleGstCalc();
  };

  const handleProceedCheckout = async () => {
    if (totalPv <= 0 || totalPv < upfsvCriteria) {
      toast.error(`Please add more products .`);
    } else {
      handleGstCalc();
      handleNext();
    }
  };
  const handleContinueShopping = () => {
    setActiveStepPr(1);
  };

  return (
    <>
      <div className="checkout_contant_area_section d-md-block d-none">
        <div className="checkout_contant_cart_sec">
          <div id="animation">
            <div className="cart-checkout">
              <div className="cart-checkout-table">
                <div className="cart-table">
                  <div className="cart-table-header">
                    <div className="cart-table-header-cell cell-flex-3 ">
                      PRODUCTS
                    </div>
                    <div className="cart-table-header-cell ">QTY</div>
                    <div className="cart-table-header-cell ">PRICE</div>
                    {/* <div className="cart-table-header-cell ">NET AMT</div>
                    <div className="cart-table-header-cell ">GST</div> */}
                    <div className="cart-table-header-cell ">AMT</div>
                    <div className="cart-table-header-cell ">REMOVE</div>
                  </div>
                  {Object.entries(cartData)?.length > 0 &&
                    Object.entries(cartData)?.map((item) => (
                      <div className="cart-table-body">
                        <div className="cart-table-body-cell cell-flex-3 ">
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <img
                              src={item[1]?.image}
                              alt=""
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                width: "100%",
                                height: "100%",
                                marginRight: "20px",
                                aspectRatio: "3 / 3",
                                objectFit: "contain",
                              }}
                            />
                            <div
                              style={{
                                textAlign: "left",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <article
                                style={{
                                  fontSize: "20px",
                                  wordBreak: "break-word",
                                  wordBreak: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {item[1]?.name}
                              </article>
                              <article>Item Code: {item[1]?.productId}</article>
                            </div>
                          </div>
                        </div>
                        <div className="cart-table-body-cell">
                          <div className="cart-cell-qty-up">
                            <div
                              id="minus"
                              onClick={(e) => {
                                handleQuantityChange(item[1], "minus");
                              }}
                            >
                              -
                            </div>
                            {item[1]?.qty}
                            <div
                              id="plus"
                              onClick={(e) => {
                                handleQuantityChange(item[1], "plus");
                              }}
                            >
                              +
                            </div>
                          </div>
                        </div>
                        <div className="cart-table-body-cell">
                          {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                            item[1].mrp,
                            conversionFactor?.value
                          )}`}
                        </div>{" "}
                        <div className="cart-table-body-cell">
                          {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                            item[1].mrp * item[1].qty,
                            conversionFactor?.value
                          )}`}
                        </div>{" "}
                        {/* <div className="cart-table-body-cell">
                          {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                            item[1].mrp * item[1].qty * gst,
                            conversionFactor?.value
                          )}`}
                        </div>
                        <div className="cart-table-body-cell">
                          {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                            item[1].mrp * item[1].qty * (1 + gst),
                            conversionFactor?.value
                          )}`}
                        </div> */}
                        <div
                          className="cart-table-body-cell"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          <button
                            className="cart-table-remove-btn"
                            onClick={(e) => {
                              handleQuantityChange(item[1], "remove");
                            }}
                          >
                            <i
                              className="fa-solid fa-xmark"
                              style={{
                                color: "#8c5331",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="cart-checkout-total-container">
                <div className="cart-checkout-total-section">
                  <div>SUB TOTAL</div>
                  <div className="cart-checkout-total-section-price">
                    {" "}
                    {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                      Number(totalAmount),
                      conversionFactor?.value
                    )}`}
                  </div>
                </div>
                <div className="cart-checkout-total-section">
                  <div>SHIPPING</div>
                  <div className="cart-checkout-total-section-price">
                    {" "}
                    {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                      Number(totalShippingCharge),
                      conversionFactor?.value
                    )}`}
                  </div>
                </div>
                {isPaymentGatewayEnabled && (
                  <div className="cart-checkout-total-section">
                    <div>PAYMENT GATEWAY FEE</div>
                    <div className="cart-checkout-total-section-price">
                      {" "}
                      {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                        (Number(totalAmount) + Number(totalShippingCharge)) *
                          pgFeePerc *
                          (1 + pgGSTPerc),
                        conversionFactor?.value
                      )}`}
                    </div>
                  </div>
                )}
                <div className="cart-checkout-total-section">
                  <div>TOTAL</div>
                  <div className="cart-checkout-total-section-price">
                    {" "}
                    {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                      (Number(totalAmount) + Number(totalShippingCharge)) *
                        (1 + pgFeePerc * (1 + pgGSTPerc)),
                      conversionFactor?.value
                    )}`}
                  </div>
                </div>
              </div>

              <div className="cart-checkout-button-container">
                <button
                  id="addMoreBtn"
                  onClick={handleContinueShopping}
                  className="addMoreBtn"
                >
                  CONTINUE SHOPPING
                </button>
                <button className="common-button-gta" onClick={handleProceedCheckout}>
                  PROCEED CHECKOUT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="d-md-none d-block checkout-mobile">
        {/* <div className="cart-table-header-cell cell-flex-3 text-start">
                    PRODUCTS
                </div> */}
        {Object.entries(cartData)?.length > 0 &&
          Object.entries(cartData)?.map((item) => (
            <div className="product-row ">
              <div className="d-flex align-items-center">
                <div className="product-image">
                  <img src={item[1]?.image} alt="" />
                </div>
                <div className="product-detail-mobile text-start">
                  <h4 className="item-name">{item[1]?.name}</h4>
                  <h6 className="item-code">Item Code: {item[1]?.productId}</h6>
                  <h6 className="item-price">
                    {" "}
                    {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                      item[1].mrp,
                      conversionFactor?.value
                    )}`}
                  </h6>
                  {/* ---Qty-- */}
                  <div className="qty-btn-parent">
                    <div class="cart-cell-qty-up">
                      <div
                        id="minus"
                        onClick={(e) => {
                          handleQuantityChange(item[1], "minus");
                        }}
                      >
                        -
                      </div>
                      <span>{item[1]?.qty}</span>
                      <div
                        id="plus"
                        onClick={(e) => {
                          handleQuantityChange(item[1], "plus");
                        }}
                      >
                        +
                      </div>
                    </div>
                    <div className="netamout">{`${
                      conversionFactor.symbolLeft
                    } ${CurrencyConverter(
                      item[1].mrp * item[1].qty,
                      conversionFactor?.value
                    )}`}</div>
                  </div>
                </div>
              </div>
              {/* --remove-- */}
              <div className="remove-product">
                <button
                  className="remove-product-btn"
                  onClick={(e) => {
                    handleQuantityChange(item[1], "remove");
                  }}
                >
                  <i
                    className="fa-solid fa-xmark"
                    style={{ color: "rgb(140, 83, 49)" }}
                  ></i>
                </button>
              </div>
            </div>
          ))}
        <div className="product-row-total mt-3">
          <div className="container-fluid">
            <div className="net-amout-clac row">
              <div className="col-auto">SUB TOTAL</div>
              <div className="col-auto">
                <span className="amout">
                  {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                    Number(totalAmount),
                    conversionFactor?.value
                  )}`}
                </span>
              </div>
            </div>
            <div className="net-amout-clac row">
              <div className="col-auto">SHIPPING</div>
              <div className="col-auto">
                <span className="amout">
                  {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                    Number(totalShippingCharge),
                    conversionFactor?.value
                  )}`}
                </span>
              </div>
            </div>
            {isPaymentGatewayEnabled && (
              <div className="net-amout-clac d-flex justify-content-between gap-2">
                <div>PAYMENT GATEWAY FEE</div>
                <div>
                  <span className="amout">
                    {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                      (Number(totalAmount) + Number(totalShippingCharge)) *
                        pgFeePerc *
                        (1 + pgGSTPerc),
                      conversionFactor?.value
                    )}`}
                  </span>
                </div>
              </div>
            )}
            <div className="net-amout-clac row">
              <div className="col-auto">
                <span className="text-bold-total">TOTAL</span>
              </div>
              <div className="col-auto">
                <span className="amout-bold">
                  {" "}
                  {`${conversionFactor.symbolLeft} ${CurrencyConverter(
                    (Number(totalAmount) + Number(totalShippingCharge)) *
                      (1 + pgFeePerc * (1 + pgGSTPerc)),
                    conversionFactor?.value
                  )}`}
                </span>
              </div>
            </div>
            <div className="row checkout-moble">
              <div className="col-auto">
                <div
                  className="continue-shop-moble common-button-gta"
                  onClick={handleContinueShopping}
                >
                  CONTINUE SHOPPING
                </div>
              </div>
              <div className="col-auto">
                <div
                  className="checkout-shop-moble common-button-gta"
                  onClick={handleProceedCheckout}
                >
                  PROCEED CHECKOUT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile-end */}
    </>
  );
};

export default UpgradeCheckoutPackage;

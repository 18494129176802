import React, { useEffect, useState } from "react";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { GetIbeCommissionReport } from "../../services/additonalModules/additionalFeature";
import { exportToExcelNew } from "../../utils/tableExports";

// const headers = [
//     { label: "IBE #", filter: "ibe", filterable: true },
//     { label: "WEEK #", filter: "weekNo", filterable: true },
//     { label: "WEEK PERIOD", filter: "weekPeriod", filterable: true },
//     { label: "IBE NAME", filter: "username", filterable: true },
//     { label: "CITY #", filter: "city", filterable: true },
//     { label: "STATE", filter: "state", filterable: true },
//     { label: "COUNTRY", filter: "country", filterable: true },
//     { label: "WEEK RANK", filter: "weekRank", filterable: true },
//     { label: "PSV ₹", filter: "psv", filterable: true },
//     { label: "LETSV", filter: "letsv", filterable: true },
//     { label: "RITSV", filter: "ritsv", filterable: true },
//     { label: "CFVL", filter: "cfvl", filterable: true },
//     { label: "CFVR", filter: "cfvr", filterable: true },
//     { label: "LTSV", filter: "ltsv", filterable: true },
//     { label: "GTSV", filter: "gtsv", filterable: true },
//     { label: "LFTSV", filter: "lftsv", filterable: true },
//     { label: "RFTSV", filter: "rftsv", filterable: true },
//     { label: "FMSV", filter: "fmsv", filterable: true },
//     { label: "QCFVL", filter: "qcfvl", filterable: true },
//     { label: "QCFVR", filter: "qcfvr", filterable: true },
//     { label: "RB", filter: "rb", filterable: true },
//     { label: "TSB", filter: "tsb", filterable: true },
//     { label: "QTSB", filter: "qsb", filterable: true },
//     { label: "GB", filter: "growthBonus", filterable: true },
//     { label: "LB", filter: "leadershipPool", filterable: true },
//     { label: "CB", filter: "carBonus", filterable: true },
//     { label: "TRB", filter: "trainingBonus", filterable: true },
//     { label: "GROSS BONUS $", filter: "gb", filterable: true },
//     { label: "TRP(6%)", filter: "tc", filterable: true },
//     { label: "TDS(5%)", filter: "tds", filterable: true },
//     { label: "ADJS", filter: "adjs", filterable: true },
//     { label: "NET BONUS $", filter: "netBonus", filterable: true },
//     { label: "NET BONUS ₹", filter: "netBounusInr", filterable: true },
//     { label: "NET BONUS ৳", filter: "netBonusBdt", filterable: true },
// ];

const IbeCommissionReport = () => {
    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});
    const [headers, setHeaders] = useState([
        { label: "IBE #", filter: "ibe", filterable: true },
        { label: "WEEK #", filter: "weekNo", filterable: true },
        { label: "WEEK PERIOD", filter: "weekPeriod", filterable: true },
        { label: "IBE NAME", filter: "username", filterable: true },
        { label: "CITY", filter: "city", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        { label: "WEEK RANK", filter: "weekRank", filterable: true },
        { label: "PSV ₹", filter: "psv", filterable: true },
        { label: "LETSV", filter: "letsv", filterable: true },
        { label: "RITSV", filter: "ritsv", filterable: true },
        { label: "CFVL", filter: "cfvl", filterable: true },
        { label: "CFVR", filter: "cfvr", filterable: true },
        { label: "LTSV", filter: "ltsv", filterable: true },
        { label: "GTSV", filter: "gtsv", filterable: true },
        { label: "LFTSV", filter: "lftsv", filterable: true },
        { label: "RFTSV", filter: "rftsv", filterable: true },
        { label: "FMSV", filter: "fmsv", filterable: true },
        { label: "QCFVL", filter: "qcfvl", filterable: true },
        { label: "QCFVR", filter: "qcfvr", filterable: true },
        { label: "RB", filter: "rb", filterable: true },
        { label: "TSB %", filter: "tsbPercent", filterable: true },
        { label: "TSB", filter: "tsb", filterable: true },
        { label: "QTSB %", filter: "qsbPercent", filterable: true },
        { label: "QTSB", filter: "qsb", filterable: true },
        { label: "GB", filter: "growthBonus", filterable: true },
        { label: "LB", filter: "leadershipPool", filterable: true },
        { label: "CB", filter: "carBonus", filterable: true },
        { label: "TB", filter: "trainingBonus", filterable: true },
        { label: "GROSS BONUS $", filter: "gb", filterable: true },
        { label: "TC(6%)", filter: "tc", filterable: true },
        { label: "TDS(5%)", filter: "tds", filterable: true },
        { label: "ADJS", filter: "adjs", filterable: true },
        { label: "NET BONUS $", filter: "netBonus", filterable: true },
        { label: "NET BONUS ₹", filter: "netBounusInr", filterable: true },
        { label: "NET BONUS ৳", filter: "netBonusBdt", filterable: true },
    ]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const startPage = 1;
    const itemsPerPage = 10;
    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const { data } = GetIbeCommissionReport(
        setTotalPages,
        currentPage,
        itemsPerPage,
        apiCallStatus,
        setApiCallStatus,
        filters
    );

    useEffect(() => {
        if (data?.userCountry) {
            if (data?.userCountry?.toLowerCase() === "india") {
                var temp = headers;
                temp = temp?.filter(
                    (item) =>
                        item?.filter !== "netBonus" &&
                        item?.filter !== "netBonusBdt"
                );
                setHeaders(temp);
            } else if (data?.userCountry?.toLowerCase() === "bangladesh") {
                var temp = headers;
                temp = temp?.filter(
                    (item) =>
                        item?.filter !== "netBonus" &&
                        item?.filter !== "netBounusInr"
                );
                setHeaders(temp);
            } else {
                var temp = headers;
                temp = temp?.filter(
                    (item) =>
                        item?.filter !== "netBonusBdt" &&
                        item?.filter !== "netBounusInr"
                );
                setHeaders(temp);
            }
        }
    }, [data]);

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    const exportFunc = () => {
        exportToExcelNew(data?.data, headers, "ibe-commission-report");
    };

    const table = data?.data && (
        <div className="report-table-container-flex">
            <div className="report-table-container scrollbar-blue">
                <CommonTableComponent
                    headers={headers}
                    datas={data?.data}
                    filters={filters}
                    firstElementSticky={true}
                    searchOption={true}
                    setFilters={setFilters}
                    onSearch={() => {
                        setApiCallStatus(true);
                    }}
                />
            </div>
        </div>
    );

    const pagination = data?.data ? (
        <div className="report-table-container-flex">
            <div className="pagination-container">
                <TablePagination
                    startPage={startPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    toNextPage={toNextPage}
                    toLastPage={toLastPage}
                    toPreviousPage={toPreviousPage}
                    toFirstPage={toFirstPage}
                    exportButton={true}
                    exportFunc={exportFunc}
                />
            </div>
        </div>
    ) : null;

    return (
        <div
            className="container-bg-design common-div-container"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <h1
                style={{ marginBottom: "10vh", marginTop: "6vh" }}
                className="bebas-neue-regular"
            >
                IBE COMMISSION REPORT
            </h1>
            {table}
            {pagination}
        </div>
    );
};

export default IbeCommissionReport;

import { React, useEffect, useRef, useState } from "react";
import FooterSection from "../components/Common/Footer";
import LeftSection from "../components/Common/LeftSection";
import HeaderSection from "../components/Common/HeaderSection";
import { Outlet, useLocation } from "react-router";
import RightContentSection from "../components/Dashboard/RightContent";
import layoutAnimation from "../utils/layoutAnimation";
import { NavLink } from "react-router-dom";
import ShoppingCart from "../components/shopping/ShoppingCart";
import { ApiHook } from "../hooks/apiHook";
import VisitersForm from "../components/Common/modals/VisitersForm";
import "../custom.css";
import { useDispatch, useSelector } from "react-redux";
import MobileFooter from "../components/Common/MobileFooter";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { demoVisitorAdded } from "../store/reducers/userReducer";
import Cookies from "js-cookie";

const MainLayout = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLeftMenuVisible, setIsLeftMenuVisible] = useState(true);
    const [leftSideBarToggle, setLeftSideBarToggle] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const dropdownRef = useRef(null);
    const toggleMenuRef = useRef(null);
    const toggleMobileRef = useRef(null);
    const containerRef = useRef(null);
    const [isQuickMenuOpen, setIsQuickMenuOpen] = useState(false);
    const [demoFormVisible, setDemoFormVisible] = useState(false);
    const [countries, setCountries] = useState([]);
    const [dashboardCheck, setDashboardCheck] = useState(false);
    const location = useLocation();

    const appLayout = ApiHook.CallAppLayout();
    const logoutMutation = ApiHook.CallLogout();
    // const rightSection = ApiHook.CallDashboardRight(
    //     dashboardCheck,
    //     setDashboardCheck
    // );
    // const checkDemoUser = ApiHook.CallCheckIsPresent();
    const userData = useSelector((state) => state.user?.loginResponse?.user);
    const userLang = useSelector((state) => state.user?.selectedLanguage);
    const user = JSON.parse(userData || "{}");

    // useEffect(() => {
    //   if (checkDemoUser?.data?.data?.isPreset === 1) {
    //     setCountries(checkDemoUser?.data?.data?.countries);
    //     const visitorId = Cookies.get("visitorID");
    //     if (visitorId) {
    //       setDemoFormVisible(false);
    //       dispatch(demoVisitorAdded(true));
    //     } else {
    //       setDemoFormVisible(true);
    //       dispatch(demoVisitorAdded(false));
    //     }
    //   }
    // }, [checkDemoUser]);

    useEffect(() => {
        // Update i18n language if savedLanguage exists
        if (userLang && userLang.code) {
            i18n.changeLanguage(userLang.code);
        }
        setMenuOpen(false); // invoke this- when change path name
    }, [userLang, location.pathname, setMenuOpen]);
    const handleLogout = async () => {
        logoutMutation.mutate();
    };

    const handleLeftMenuToggle = () => {
        // setIsLeftMenuVisible(!isLeftMenuVisible);
        setLeftSideBarToggle(!leftSideBarToggle);
    };
    const handleQuickMenuToggle = () => {
        setIsQuickMenuOpen(!isQuickMenuOpen);
    };
    console.log(location.pathname, "111111111111111");
    useEffect(() => {
        if (location.pathname) {
            setIsQuickMenuOpen(false);
            setIsLeftMenuVisible(false);
        }
        if (location.pathname === "/dashboard") {
            setDashboardCheck(true);
        }
        layoutAnimation(location, containerRef);
        const handleOutsideClick = (event) => {
            if (!dropdownRef?.current?.contains(event.target)) {
                setIsQuickMenuOpen(false);
            }
            if (
                !(
                    toggleMenuRef?.current?.contains(event.target) ||
                    toggleMobileRef?.current?.contains(event.target)
                )
            ) {
                setLeftSideBarToggle(false);
                setIsLeftMenuVisible(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [location.pathname]);

    return (
        <>
            <div className={`${isLeftMenuVisible ? "left_menu_show" : ""}`}>
                <main
                    className={
                        location.pathname === "/dashboard"
                            ? "dashboard_main_dv"
                            : "main_dv"
                    }
                >
                    {leftSideBarToggle && (
                        <button
                            className="menu_toggle_btn"
                            onClick={() => {
                                setLeftSideBarToggle(!leftSideBarToggle);
                            }}
                        >
                            <i
                                className="fa-solid fa-times"
                                aria-hidden="true"
                            ></i>
                        </button>
                    )}
                    <section className="left_content_section">
                        {location.pathname === "/dashboard" && (
                            <HeaderSection
                                count={appLayout?.data?.notificationCount}
                                handleLeftMenuToggle={handleLeftMenuToggle}
                                userName={user?.username}
                                user={user}
                                appLayout={appLayout?.data}
                                toggleMobileRef={toggleMobileRef}
                            />
                        )}

                        {/* NAVBAR */}
                        {location.pathname !== "/dashboard" && (
                            <>
                                <div>
                                    <button
                                        className="menu_toggle_btn"
                                        onClick={() => {
                                            setMenuOpen((state) => !state);
                                        }}
                                    >
                                        {
                                            !menuOpen ? (
                                                <i className="fa-solid fa-bars"></i>
                                            ) : (
                                                <i
                                                    className="fa-solid fa-times"
                                                    aria-hidden="true"
                                                    style={{ color: "#fff" }}
                                                ></i>
                                            ) // Use consistent class
                                        }
                                    </button>
                                </div>
                            </>
                        )}
                        <div
                            className={`menu_in_all ${
                                menuOpen ? "menuopen" : ""
                            }`}
                        >
                            {(location.pathname === "/dashboard" ||
                                !!menuOpen) && (
                                <LeftSection
                                    leftSideBarToggle={leftSideBarToggle}
                                    isLeftMenuVisible={true}
                                    handleLeftMenuToggle={handleLeftMenuToggle}
                                    menus={appLayout?.data?.menu?.sideMenus}
                                    spclMenu={appLayout?.data?.menu?.spclMenu}
                                    toggleMenuRef={toggleMenuRef}
                                    logo={appLayout?.data?.companyProfile?.logo}
                                />
                            )}
                        </div>
                        <div
                            ref={containerRef}
                            style={{
                                position: "relative",
                                width: "100%",
                                // height: "100%",
                            }}
                        >
                            <div
                                data-tut="main-layout"
                                className={
                                    location.pathname === "/dashboard"
                                        ? "center_Content_section"
                                        : ""
                                }
                            >
                                <Outlet />
                            </div>
                        </div>
                        {location.pathname === "/dashboard" && (
                            <FooterSection />
                        )}
                    </section>
                </main>
                {/* {location.pathname !== "/dashboard" && <FooterSection />} */}
                {location.pathname === "/dashboard" && (
                    <div className="float_menu_btm" ref={dropdownRef}>
                        <button
                            className={`dropdown-toggle ${
                                isQuickMenuOpen ? "show" : ""
                            }`}
                            onClick={handleQuickMenuToggle}
                            aria-expanded={isQuickMenuOpen}
                        >
                            <i className="fa-solid fa-bars"></i>
                        </button>
                        {isQuickMenuOpen && (
                            <div
                                className="dropdown-menu usr_prfl right-0 show"
                                style={{
                                    position: "fixed",
                                    inset: "auto 0px 0px auto",
                                    margin: "0px",
                                    transform: "translate(-50px, -102px)",
                                }}
                                data-popper-placement="top-end"
                            >
                                <ul>
                                    {appLayout?.data?.menu?.quickMenus.map(
                                        (menuItem, index) => (
                                            <li key={index}>
                                                <NavLink
                                                    to={`/${menuItem.slug}`}
                                                    className={`dropdown-item ${({
                                                        isActive,
                                                    }) =>
                                                        isActive
                                                            ? "active"
                                                            : ""}`}
                                                >
                                                    <i
                                                        className={`${menuItem.quickIcon}`}
                                                    ></i>{" "}
                                                    {t(menuItem.slug)}
                                                </NavLink>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {location.pathname === "/dashboard" && (
                <MobileFooter menus={appLayout?.data?.menu?.sideMenus} />
            )}
        </>
    );
};

export default MainLayout;

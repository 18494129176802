import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useInView } from "react-intersection-observer";
import {
    AddNewComment,
    GetTicketComments,
    GetTicket,
} from "../../services/additonalModules/additionalFeature";
import { useQueryClient } from "@tanstack/react-query";
import { addNewMessageToInfiniteData } from "../../utils/addToQueryData";
import CommonInput from "../../components/Common/CommonInputComponent";
import moment from "moment";

const ChatLeft = ({ message }) => {
    return (
        <div className="chat-message left">
            <div className="message-content">
                <div className="label_name admin">Admin</div>
                <p className="text-start mb-2">{message.message}</p>
                <div className="dateandtime">
                    {moment(message.createdAt).format("MM/DD/YYYY, hh:mm A")}
                </div>
            </div>
        </div>
    );
};

const ChatRight = ({ message }) => {
    return (
        <div className="chat-message right">
            <div className="message-content">
                <div className="label_name user">You</div>
                <p className="text-start mb-2">{message.message}</p>
                <div className="dateandtime">
                    {moment(message.createdAt).format("MM/DD/YYYY, hh:mm A")}
                </div>
            </div>
        </div>
    );
};

const statusColorLookUp = {
    pending: { backgroundColor: "#c7e6d3", color: "#66bc7e" },
    approved: { backgroundColor: "#E4F0F2", color: "#2E8CA5" },
    rejected: { backgroundColor: "#FEE4D1", color: "#9B5731" },
};
let color = statusColorLookUp["pending"]?.color;
let backgroundColor = statusColorLookUp["pending"]?.backgroundColor;

const Comment = () => {
    const [message, setMessage] = useState("");

    const parms = useParams();
    const { ref: messageTopRef, inView: messageTopView } = useInView();

    const { ticketId } = parms;
    const queryClient = useQueryClient();

    const { data, fetchNextPage, isFetching } = GetTicketComments(ticketId);
    const { data: ticketDetail } = GetTicket(ticketId);

    const commentMutation = AddNewComment();

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!message) {
            return;
        }

        const data = {
            message,
            ticketId,
        };

        commentMutation.mutate(data, {
            onSuccess: (res) => {
                if (res?.status) {
                    setMessage("");
                    addNewMessageToInfiniteData(
                        queryClient,
                        res?.data?.data,
                        ticketId
                    );
                    queryClient.invalidateQueries("ticket", ticketDetail.id);
                }
            },
        });
    };

    const comments = data?.pages?.flatMap((p) => p.data) || [];

    useEffect(() => {
        if (messageTopView) {
            fetchNextPage();
        }
    }, [messageTopView]);

    return (
        <>
            <div className="container-bg-design common-div-container">
                <h1
                    style={{ marginTop: "6vh" }}
                    className="bebas-neue-regular gta-b"
                >
                    ticket
                </h1>
                <div className="d-flex justify-content-center">
                    <div className="change-password-form-container">
                        <CommonInput
                            label="TICKET NUMBER"
                            placeholder=""
                            value={ticketId}
                            backgroundColor="#e4f0f2"
                        />
                        <CommonInput
                            label="CATEGORY"
                            placeholder=""
                            value={ticketDetail?.category}
                            backgroundColor="#e4f0f2"
                        />
                        <CommonInput
                            label="PRIORITY"
                            placeholder=""
                            value={ticketDetail?.priority}
                            backgroundColor="#e4f0f2"
                        />
                        <CommonInput
                            label="SUBJECT"
                            placeholder=""
                            value={ticketDetail?.subject || ""}
                            backgroundColor="#e4f0f2"
                        />
                        <CommonInput
                            label="STATUS"
                            placeholder=""
                            value={ticketDetail?.status}
                            backgroundColor="#e4f0f2"
                        />
                        {/* <div>message: {ticketDetail?.message}</div> */}
                        <div className="common-input-container">
                            {ticketDetail?.attachment?.map((i) => {
                                return (
                                    <a
                                        href={i}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={i}
                                            alt=""
                                            style={{
                                                cursor: "pointer",
                                                maxWidth: "150px",
                                                width: "100%",
                                                objectFit: "contain",
                                                aspectRatio: "3 / 2",
                                            }}
                                        />
                                    </a>
                                );
                            })}
                        </div>
                        <h6 className="custom-label mb-3 mt-4">
                            {" "}
                            MESSAGE THREADS{" "}
                        </h6>
                        <div
                            className="square-border-cut chat-component"
                            style={{ flex: 1, position: "relative" }}
                        >
                            <div className="chat-container">
                                {/* Chat Messages Container */}
                                <div className="chat-messages">
                                    {comments.map((m, i) => {
                                        return m.userId === 1 ? (
                                            <ChatLeft key={i} message={m} />
                                        ) : (
                                            <ChatRight key={i} message={m} />
                                        );
                                    })}
                                    <ChatRight
                                        message={{
                                            message: ticketDetail?.message,
                                            createdAt: ticketDetail?.createdAt,
                                        }}
                                    />
                                    <div ref={messageTopRef}>
                                        {isFetching ? "Loading.." : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* -- message box-- */}
                        <div>
                            <h6 className="custom-label mb-3 mt-4">
                                {" "}
                                COMMENT{" "}
                            </h6>
                            <div
                                style={{
                                    position: "relative",
                                    margin: "10px 0",
                                    width: "100%",
                                }}
                                className="square-border-cut chat-component"
                            >
                                <textarea
                                    style={
                                        !message
                                            ? {
                                                  resize: "none",
                                                  padding: "80px 0 0 15px",
                                                  background: "transparent",
                                                  border: "none",
                                              }
                                            : {
                                                  resize: "none",
                                                  background: "transparent",
                                              }
                                    }
                                    className="form-control no-active-outline ticket-text-area"
                                    value={message}
                                    onChange={handleMessageChange}
                                    placeholder="Type your Comment here..."
                                />
                            </div>
                        </div>

                        {/* submit */}
                        {commentMutation.isLoading === true ? (
                            <div className="spinner-ticket"></div>
                        ) : (
                            <button
                                style={{ margin: "20px 0" }}
                                className="button-maxwidth"
                                onClick={handleSubmit}
                            >
                                <span
                                    style={{ fontWeight: 700, color: "white" }}
                                >
                                    SUBMIT
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Comment;

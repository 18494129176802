import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
const IbeCancelCustomerOrderReport = () => {
    const { t } = useTranslation();
    const headers = [
        // { label: "SELLER IBE #", filter: "seller_ibe", filterable: true },
        // {
        //     label: "SELLER IBE NAME",
        //     filter: "seller_ibe_name",
        //     filterable: true,
        // },
        { label: "CS #", filter: "cs", filterable: true },
        { label: "CS NAME", filter: "cs_name", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        { label: "ORD #", filter: "ord", filterable: true },
        { label: "ORD DATE", filter: "ord_date", filterable: true },
        { label: "ORD AMT $", filter: "ord_amt_usd", filterable: true },
        { label: "ORD AMT ₹", filter: "ord_amt_inr", filterable: true },
        { label: "PAY MODE", filter: "pay_mode", filterable: true },
        { label: "DELIVERY MODE", filter: "delivery_mode", filterable: true },
        // { label: "TXN ID", filter: "txn_id", filterable: true },
        { label: "INVOICE", filter: "invoice", filterable: true },
        { label: "SV", filter: "sv", filterable: true },
    ];

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const ibeOrderReport = ApiHook.CallgetIbeCustomerOrderReport({
        filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
    });

    const startPage = 1;
    const itemsPerPage = 10;
    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }
    return (
        <>
            <div className="report-Container">
                <div className="report-header">
                    IBE CANCEL CUSTOMER ORDER REPORT
                </div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={ibeOrderReport?.data?.data}
                            filters={filters}
                            firstElementSticky={false}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {ibeOrderReport?.data?.data &&
                            ibeOrderReport?.data?.data?.length !== 0 && (
                                <TablePagination
                                    startPage={startPage}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    setCurrentPage={setCurrentPage}
                                    itemsPerPage={itemsPerPage}
                                    toNextPage={toNextPage}
                                    toLastPage={toLastPage}
                                    toPreviousPage={toPreviousPage}
                                    toFirstPage={toFirstPage}
                                    exportButton={true}
                                />
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeCancelCustomerOrderReport;

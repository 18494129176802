import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
// import { formatDate } from "../../utils/formateDate";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useReactToPrint } from "react-to-print";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";

const EventPurchaseComplete = () => {
  const { t } = useTranslation();
  const tableRef = useRef();
  const [conversionFactor, setConversionFactor] = useState();

  const userSelectedCurrency = useSelector(
    (state) => state?.user?.selectedCurrency
  );

  const [successMessageShow, setSuccessMessageShow] = useState(true);
  const params = useParams();
  const id = params?.id;
  const preview = ApiHook.CallEventInvoice(id);
  const user = preview?.data?.userData ?? {};
  const evntPurchaseDetails = preview?.data?.evntPurchaseDetails ?? {};

  const closeAlert = () => {
    setSuccessMessageShow(false);
  };
  useEffect(() => {
    setConversionFactor(
      preview?.data?.userCountry.toLowerCase() === "india"
        ? {
            symbolLeft: preview?.data?.activeCurrencies["INR"]?.symbol,
            value: preview?.data?.activeCurrencies["INR"]?.value,
          }
        : preview?.data?.userCountry.toLowerCase() === "bangladesh"
        ? {
            symbolLeft: preview?.data?.activeCurrencies["BDT"]?.symbol,
            value: preview?.data?.activeCurrencies["BDT"]?.value,
          }
        : {
            symbolLeft: preview?.data?.activeCurrencies["USD"]?.symbol,
            value: preview?.data?.activeCurrencies["USD"]?.value,
          }
    );
  }, [preview?.data]);

  // Use useEffect to automatically call closeAlert after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      closeAlert();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [successMessageShow]);

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  return (
    <div style={{ height: "100vh" }}>
      <div className="container-bg-design" style={{ height: "100vh" }}>
        <div className="col-md-8 m-auto">
          <div className="purchase_invoice" ref={tableRef}>
            <div
              className="alertNotification"
              style={{
                width: "100%",
                border: "1px solid rgb(153 88 53)",
                background: "rgba(153, 88, 53, 0.4)",
                display: "inline-block",
                color: "rgb(153 88 53)",
                padding: "8px 15px 8px 15px",
                // borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <p style={{ margin: "0" }}>
                {t("Event Purchased Successfully")}{" "}
                {user?.status === "pending" &&
                  " but is still awaiting final confirmation."}
              </p>
            </div>
            <h3 style={{ margin: "20px" }}>Event Details</h3>
            {evntPurchaseDetails ? (
              <>
                <table className="striped">
                  <thead
                    style={{
                      backgroundColor: "rgb(153 88 53)",
                      color: "white",
                    }}
                  >
                    {" "}
                    <tr>
                      <th
                        className="text-uppercase  font-weight-bold"
                        style={{ textAlign: "center" }}
                      ></th>{" "}
                      <th className="text-uppercase  font-weight-bold"></th>{" "}
                    </tr>
                  </thead>
                  <tbody>
                  	
                    <tr className="font-weight-bold mb-1">
                      <td>{t("Invoice No")}</td>
                      <td>{evntPurchaseDetails?.invoiceId}</td>
                    </tr>
                    <tr className="font-weight-bold mb-1">
                      <td>{t("username")}</td>
                      <td>{user?.username}</td>
                    </tr>

                    <tr className="font-weight-bold mb-1">
                      <td>{t("Event Amount")}</td>
                      <td>
                        {`${
                          conversionFactor?.symbolLeft
                        } ${CustomCurrencyConverter(
                          Number(evntPurchaseDetails?.eventAmount),
                          conversionFactor?.value
                        )}`}
                      </td>
                    </tr>
                    {Number(evntPurchaseDetails.paymentAmount) > 0 && (
                      <>
                        <tr className="font-weight-bold mb-1">
                          <td>{t("Paid Amount")}</td>
                          <td>
                            {`${
                              conversionFactor?.symbolLeft
                            } ${CustomCurrencyConverter(
                              Number(evntPurchaseDetails.paymentAmount),
                              conversionFactor?.value
                            )}`}
                          </td>
                        </tr>
                        <tr className="font-weight-bold mb-1">
                          <td>{t("Payment Method")}</td>
                          <td>
                            {evntPurchaseDetails["PaymentGatewayConfig.name"]}
                          </td>
                        </tr>
                      </>
                    )}

                    {Number(evntPurchaseDetails?.tariningWalletAmount) > 0 && (
                      <tr className="font-weight-bold mb-1">
                        <td>{t("Paid from Training wallet")}</td>

                        <td>
                          {`${
                            conversionFactor?.symbolLeft
                          } ${CustomCurrencyConverter(
                            Number(evntPurchaseDetails?.tariningWalletAmount),
                            conversionFactor?.value
                          )}`}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <img
                  src={evntPurchaseDetails["event.banner"]}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    maxHeight:"1200px"
                  }}
                />
              </>
            ) : (
              <>
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/no-data-image1.jpg" alt="" />
                    </div>
                    <div className="nodata-table-view-box-txt">
                      {t("sorry_no_data_found")}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPurchaseComplete;

import React, { useState } from "react";
import CommonInput from "../Common/CommonInputComponent";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";

const CheckoutPayment = ({
    formValues,
    handleFormValueChange,
    setActiveStep,
    registerFields,
    totalAmount,
    conversionFactor,
    handleSubmit,
    gst,
    replicaRegisterMutation,
    totalShippingCharge,
    paymentGatewayFeeConf,
}) => {
    const { t } = useTranslation();
    const backgroundColor = "#fff";
    const [transPass, setTransPass] = useState("");
    const [transPassCheck, setTransPassCheck] = useState(false);
    const [transPassResposne, setTransPassResposne] = useState({
        success: null,
        error: null,
    });
    const [submitButtonActive, setSubmitButtonActive] = useState(true);

    const transPassCheckData = ApiHook.CallReplicaTransPasswordCheck(
        transPass,
        transPassCheck,
        setTransPassCheck,
        setSubmitButtonActive,
        Number(totalAmount) + Number(totalShippingCharge),
        //  *
        //     (1 +
        //         paymentGatewayFeeConf?.pgFeePerc *
        //             (1 + paymentGatewayFeeConf?.pgGSTPerc)),
        transPassResposne,
        setTransPassResposne,
        "e-wallet",
        registerFields?.sponsorData?.username
    );
    return (
        <>
            <div className="checkout_contant_area_section">
                <div id="animation">
                    <div className="checkout_contant_cart_sec">
                        <div className="">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <div className="register-verification-container">
                                    <CommonInput
                                        type="text"
                                        label="Total Amount"
                                        backgroundColor={backgroundColor}
                                        value={`${
                                            conversionFactor?.symbolLeft
                                        } ${CustomCurrencyConverter(
                                            Number(totalAmount) +
                                                Number(totalShippingCharge),
                                            // *
                                            // (1 +
                                            //     paymentGatewayFeeConf?.pgFeePerc *
                                            //         (1 +
                                            //             paymentGatewayFeeConf?.pgGSTPerc))
                                            conversionFactor?.value
                                        )}`}
                                        defaultValue=""
                                        disabled={true}
                                    />
                                    <CommonInput
                                        type="text"
                                        label="IBE #"
                                        backgroundColor={backgroundColor}
                                        value={
                                            registerFields?.sponsorData
                                                ?.username
                                        }
                                        onChange={(e) => {
                                            handleFormValueChange(
                                                e.target.value,
                                                "walletUsername"
                                            );
                                            setTransPass(e.target.value);
                                        }}
                                        defaultValue=""
                                    />
                                    <CommonInput
                                        type="password"
                                        label="Transaction Password"
                                        placeholder={
                                            "Your Transaction password"
                                        }
                                        backgroundColor={backgroundColor}
                                        value={formValues?.transactionPassword}
                                        onChange={(e) => {
                                            handleFormValueChange(
                                                e.target.value,
                                                "transactionPassword"
                                            );
                                            setTransPass(e.target.value);
                                        }}
                                        defaultValue=""
                                    />
                                    {transPassResposne?.error && (
                                        <span className="error-message-validator">
                                            {t(transPassResposne?.error)}
                                        </span>
                                    )}

                                    <button
                                        className="common-button"
                                        onClick={(e) => {
                                            setTransPassCheck(true);
                                            handleFormValueChange(
                                                2,
                                                "paymentType",
                                                {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                }
                                            );
                                        }}
                                    >
                                        CHECK
                                    </button>
                                    {transPassCheckData?.data?.status && (
                                        <button
                                            className="common-button"
                                            onClick={(e) => {
                                                handleSubmit();
                                            }}
                                            disabled={
                                                replicaRegisterMutation.status ===
                                                "loading"
                                            }
                                            isLoading={
                                                replicaRegisterMutation.status ===
                                                "loading"
                                            }
                                        >
                                            SUBMIT
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckoutPayment;

import React, { useRef, useEffect, useState } from 'react';
import captchImage from "../../assests/images/captch-spin.png"

const CaptchaImage = ({ setCaptchaText, reload }) => {
  const canvasRef = useRef(null);
  const [spinning, setSpinning] = useState(false);

  const generateCaptcha = () => {
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const drawCaptcha = (text) => {
    const canvas = canvasRef.current;

    if(!canvas) return;
    
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background
    ctx.fillStyle = '#f0f0f0';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Text
    ctx.font = 'bold 30px Arial';
    ctx.textBaseline = 'middle';

    for (let i = 0; i < text.length; i++) {
      ctx.save();
      ctx.translate(25 + i * 25, canvas.height / 2);
      
      // Random rotation
      ctx.rotate((Math.random() - 0.5) * 0.4);
      
      // Random vertical offset
      const verticalOffset = (Math.random() - 0.5) * 10;
      
      // Wavy text effect
      const x = 0;
      const y = Math.sin(i * 0.5) * 5 + verticalOffset;
      
      // Random color
      ctx.fillStyle = `rgb(${Math.floor(Math.random()*200)}, ${Math.floor(Math.random()*200)}, ${Math.floor(Math.random()*200)})`;
      
      ctx.fillText(text[i], x, y);
      ctx.restore();
    }

    // Add some arcs in the background for extra noise
    ctx.strokeStyle = 'rgba(180, 180, 180, 0.3)';
    for (let i = 0; i < 5; i++) {
      ctx.beginPath();
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const radius = Math.random() * 20 + 10;
      ctx.arc(x, y, radius, 0, Math.PI * 2);
      ctx.stroke();
    }
  };

  const refreshCaptcha = () => {
    setSpinning(true);
    setTimeout(() => {
        const newCaptcha = generateCaptcha();
        setCaptchaText(newCaptcha);
        drawCaptcha(newCaptcha);
        setSpinning(false);
    }, 500);
  };

  useEffect(() => {
    refreshCaptcha();
  }, [reload]);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
      <canvas ref={canvasRef} width="148" height="68" />
      <button style={{ background: "transparent" }} onClick={refreshCaptcha}>
        <div className={ spinning ? "spin-captcha-button" : "stop-spin" }>
            <img width="30px" height="24px" src={captchImage} alt="REFRESH CAPTCHA" />
        </div>
      </button>
    </div>
  );
};

export default CaptchaImage;
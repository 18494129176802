import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "react-js-loader";
import { colors } from "@mui/material";
import CommonInput from "../Common/CommonInputComponent";

const IbeRegisterCart = ({
    formValues,
    setActiveStep,
    products,
    activeCurrencies,
    fsvCriteria,
    cartData,
    setCartData,
    setTotalAmount,
    setTotalPv,
    totalAmount,
    totalPv,
    handleGstCalc,
    handleFormValueChange,
}) => {
    const { t } = useTranslation();
    const [cartProducts, setCartProducts] = useState(products);
    const [options1Active, setOptions1Active] = useState(false);
    const [isDefaultProductAdded, setisDefaultProductAdded] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({
        repurchaseError: "",
        courierError: "",
    });

    useEffect(() => {
        setCartProducts(products);
    }, [products]);

    const conversionFactor =
        (formValues?.country).toLowerCase() === "india"
            ? {
                  symbolLeft: activeCurrencies["INR"]?.symbol,
                  value: activeCurrencies["INR"]?.value,
              }
            : (formValues?.country).toLowerCase() === "bangladesh"
            ? {
                  symbolLeft: activeCurrencies["BDT"]?.symbol,
                  value: activeCurrencies["BDT"]?.value,
              }
            : {
                  symbolLeft: activeCurrencies["USD"]?.symbol,
                  value: activeCurrencies["USD"]?.value,
              };

    const handleQuantityChange = (product, type) => {
        const { id: packageId, price, mrp, fsv, gst, weight } = product;

        if (type === "plus") {
            setCartData((cart) => ({
                ...cart,
                [packageId]: {
                    ...product,
                    qty: cart[packageId] ? cart[packageId]?.qty + 1 : 1,
                },
            }));
            setTotalAmount((total) => total + Number(mrp));
            setTotalPv((total) => total + Number(fsv));
        } else if (
            type === "minus" &&
            cartData[packageId] &&
            cartData[packageId]?.qty > 0
        ) {
            if (cartData[packageId]?.qty === 1) {
                let tempCart = cartData;
                delete tempCart[packageId];
                setCartData(tempCart);
            } else {
                setCartData((cart) => ({
                    ...cart,
                    [packageId]: {
                        ...product,
                        qty: cart[packageId] ? cart[packageId]?.qty - 1 : 0,
                    },
                }));
            }
            let [cumulativeTotalAmount, cumulativeTotalPv] = [
                totalAmount - Number(mrp),
                totalPv - Number(fsv),
            ];
            setTotalAmount(cumulativeTotalAmount);
            setTotalPv(cumulativeTotalPv);
        }
    };

    console.log("11111111111111111111", cartData);
    useEffect(() => {
        if (Object.keys(cartData)?.length === 0) {
            let defaultProduct = cartProducts?.filter(
                (item) =>
                    item?.productId === "FI004" || item?.productId === "FB004"
            );
            handleQuantityChange(defaultProduct[0], "plus");
        }
    }, []);

    const gotoCart = async () => {
        if (!formValues?.courierType) {
            setErrorMessage({
                courierError: "Courier Type is required",
            });
        } else {
            if (totalPv < fsvCriteria) {
                toast.error(`Please add more products .`);
            } else {
                await handleGstCalc();
                setActiveStep(5);
            }
        }
    };

    const colorsList = [
        "#E4F0F2",
        "#E5F1DC",
        "#F9F4EF",
        "#d9f9dd",
        "#f8f0dd",
        "#def8fe",
    ];
    return (
        <div className="register-cart-container">
            <div className="common-div-header gta-b">
                IBE SIGNUP SHOPPING CART
            </div>
            <div style={{}}>
                {(formValues?.country).toLowerCase() !== "bangladesh" && (
                    <div className="repurchase-input-container-flex">
                        {
                            <div className="repurchase-input-container px-3">
                                {/* <div
                                    className="repurchase-input-div  align-items-center"
                                    style={
                                        options1Active
                                            ? {
                                                  "--pseudo-element-color":
                                                      "#fff",
                                                  height: "94px",
                                                  display: "flex",
                                                  justifyContent:
                                                      "space-between",
                                              }
                                            : {
                                                  "--pseudo-element-color":
                                                      "#fff",
                                                  display: "flex",
                                                  justifyContent:
                                                      "space-between",
                                              }
                                    }
                                >
                                    <div>
                                        {!options1Active &&
                                            !formValues?.courierType && (
                                                <div
                                                    className="repurchase-form-new-input"
                                                    style={
                                                        options1Active
                                                            ? {
                                                                  height: "50%",
                                                              }
                                                            : {}
                                                    }
                                                    onClick={() => {
                                                        setOptions1Active(true);
                                                    }}
                                                >
                                                    Select Delivery Mode
                                                </div>
                                            )}
                                        {(options1Active ||
                                            formValues?.courierType ===
                                                "Courier") && (
                                            <div
                                                className="repurchase-form-new-input"
                                                style={
                                                    options1Active
                                                        ? {
                                                              height: "50%",
                                                          }
                                                        : {}
                                                }
                                                onClick={() => {
                                                    if (!options1Active) {
                                                        setOptions1Active(true);
                                                    } else {
                                                        handleFormValueChange(
                                                            "Courier",
                                                            "courierType"
                                                        );
                                                        setOptions1Active(
                                                            false
                                                        );
                                                        setErrorMessage({
                                                            courierError: "",
                                                        });
                                                    }
                                                }}
                                            >
                                                Courier
                                            </div>
                                        )}
                                        {(options1Active ||
                                            formValues?.courierType ===
                                                "Selfpickup") && (
                                            <div
                                                className="repurchase-form-new-input"
                                                style={
                                                    options1Active
                                                        ? {
                                                              height: "50%",
                                                          }
                                                        : {}
                                                }
                                                onClick={() => {
                                                    if (!options1Active) {
                                                        setOptions1Active(true);
                                                    } else {
                                                        handleFormValueChange(
                                                            "Selfpickup",
                                                            "courierType"
                                                        );
                                                        setOptions1Active(
                                                            false
                                                        );
                                                        setErrorMessage({
                                                            courierError: "",
                                                        });
                                                    }
                                                }}
                                            >
                                                Self pickup
                                            </div>
                                        )}
                                    </div>

                                    <button
                                        style={{
                                            height: "50px",
                                            width: "50px",
                                            backgroundColor: "transparent",
                                            color: "#9B5731",
                                        }}
                                        onClick={() => {
                                            setOptions1Active(
                                                (state) => !state
                                            );
                                        }}
                                    >
                                        {options1Active ? (
                                            <i className="fa-solid fa-caret-up"></i>
                                        ) : (
                                            <i className="fa-solid fa-caret-down"></i>
                                        )}
                                    </button>
                                </div> */}
                                <CommonInput
                                    type="select"
                                    label="Select Delivery Mode"
                                    // backgroundColor={"#fff"}
                                    value={formValues?.courierType}
                                    onChange={(e) => {
                                        handleFormValueChange(
                                            e.target.value,
                                            "courierType"
                                        );
                                    }}
                                    options={[
                                        // "Select Delivery Mode",
                                        "Courier",
                                        "Selfpickup",
                                    ]}
                                    defaultValue=""
                                    isLabelDisabled={true}
                                />
                                {errorsMessage?.courierError !== "" && (
                                    <span className="error-message-validator">
                                        {errorsMessage?.courierError}
                                    </span>
                                )}
                            </div>
                        }
                    </div>
                )}
                <div className="shoping_page_section">
                    <div className="row">
                        {cartProducts?.length ? (
                            cartProducts
                                ?.filter(
                                    (item) =>
                                        item?.productId !== "FI004" &&
                                        item?.productId !== "FB004"
                                )
                                ?.map((product, index) => (
                                    <div
                                        className="col-xxl-3 col-xl-4 col-sm-6 d-sm-block d-flex justify-content-center"
                                        key={index}
                                    >
                                        <div className="product_box_container">
                                            <div
                                                className="product_box"
                                                style={{
                                                    backgroundColor:
                                                        colorsList[index % 6],
                                                }}
                                            >
                                                <div className="product_box_product">
                                                    {" "}
                                                    <NavLink>
                                                        <div className="product_box_image">
                                                            <img
                                                                src={
                                                                    product.image ??
                                                                    "/images/product3.jpg"
                                                                }
                                                                alt="product"
                                                            />
                                                        </div>
                                                    </NavLink>
                                                    <div className="product_box_content">
                                                        <div className="product_box_head">
                                                            {product.name}
                                                        </div>
                                                        <div className="product_box_category">
                                                            {`SV ${
                                                                conversionFactor?.value *
                                                                product.fsv
                                                            }`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product_box_btn_sec">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <p className="product_box_amnt m-0">{`${
                                                        conversionFactor.symbolLeft
                                                    } ${CurrencyConverter(
                                                        product.mrp,
                                                        conversionFactor?.value
                                                    )}`}</p>
                                                </div>
                                                <div className="add_cart_button_p">
                                                    <div className="product_box_btn2 d-flex">
                                                        <div className=" product_box_btn2_text">
                                                            {cartData[
                                                                product.id
                                                            ]?.qty ?? 0}
                                                        </div>
                                                        <div>
                                                            <button
                                                                className="product_box_btn2_button"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    handleQuantityChange(
                                                                        product,
                                                                        "plus"
                                                                    );
                                                                }}
                                                            >
                                                                <i
                                                                    className="fa fa-chevron-up"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                            <button
                                                                className="product_box_btn2_button"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    handleQuantityChange(
                                                                        product,
                                                                        "minus"
                                                                    );
                                                                }}
                                                            >
                                                                <i
                                                                    className="fa fa-chevron-down"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {/* <NavLink
                                                    className="product_box_btn2_cart"
                                                    to={`/checkout/${repurchaseType}`}
                                                    onClick={(e) => {
                                                        if (
                                                            repurchaseType ===
                                                            ""
                                                        ) {
                                                            e.preventDefault();
                                                            setErrorMessage(
                                                                "Repurchase type is required"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <i
                                                        className="fa-solid fa-cart-shopping"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    ></i>
                                                </NavLink> */}
                                                    <button
                                                        className="product_box_btn2_cart"
                                                        onClick={gotoCart}
                                                    >
                                                        <i
                                                            className="fa-solid fa-cart-shopping"
                                                            style={{
                                                                color: "#fff",
                                                            }}
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            // placeholder image
                            <div className="newsMainBg">
                                <img src="images/news-no-data.png" alt="" />
                                <div>{t("sorry_no_data_found")}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IbeRegisterCart;

import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { ApiHook } from "../../hooks/apiHook";
import logo_user from "../../assests/images/invoiceLogo.png";

import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { useSearchParams } from "react-router-dom";
import InvoiceModal from "./InvoiceModal";

const PurchaseInvoice = ({
  invoiceNoProps = null,
  typeProps = null,
  customStyle = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const isSuccess = searchParams.get("success");

  const invoiceNo = !invoiceNoProps ? params.id : invoiceNoProps;
  const type = !typeProps ? params.type : typeProps;
  const invoice = ApiHook.CallRepurchaseInvoice(invoiceNo, type);

  const user = invoice?.data?.userData;
  // console.log(user);

  const handleBack = () => {
    navigate("/repurchase-report");
  };

  const handlenavigation = () => {
    navigate("/dashboard");
  };
  return (
    <div style={{ height: "100dvh" }}>
      <div className="container-bg-design" style={{ height: "100vh" }}>
        {isSuccess ? (
          <>
            <div className="succuss-padding mb-2">
              <div className="register-invoice-succuss">
             

                <div style={{ maxWidth: "100%", padding: "10px" }}>
                  <h3 className="common-div-header gta-b pt-0 mb-3">
                    ORDER COMPLETED
                  </h3>
                  <span
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      color: "black",
                    }}
                  >
                    {`Thank You! ${user?.UserDetail?.name ?? ""} ${
                      user?.UserDetail?.secondName ?? ""
                    },your order has been confirmed.`}
                  </span>
                  <br />
                  <span style={{ display: "block", marginTop: "10px" }}>
                    {`Your Order Number is`}{" "}
                    <label className="fs-md-14 bol-3">{invoiceNo}.</label>
                  </span>
                  <span
                    style={{ display: "block", marginTop: "10px" }}
                    className="fs-md-14"
                  >
                    {`You must collect the self-pick-up Order within 10 days of the order date.`}
                  </span>
                  <span
                    style={{ display: "block", marginTop: "10px" }}
                    className="fs-md-14"
                  >
                    {`Otherwise, the company will send you self-pick-up order products by courier.`}
                  </span>
                  <button
                    className="mt-3 common-button d-block"
                    style={{ maxWidth: "380px", margin: "0 auto" }}
                    onClick={() => handlenavigation()}
                  >
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <InvoiceModal invoiceNo={invoiceNo} type={type} invoice={invoice} />
        )}
      </div>
    </div>
  );
};

export default PurchaseInvoice;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import CommonInput from "../../components/Common/CommonInputComponent";
import CustomFileInput from "../../components/Common/CustomFileInput";
import { useForm } from "react-hook-form";
import {
    isANumber,
    isAlphaOnly,
    isAlphanumeric,
    isMaxLength,
} from "../../utils/validationHealpers";
import { toast } from "react-toastify";
import { exportToExcelNew } from "../../utils/tableExports";
const AgentWalletTransfer = () => {
    const { t } = useTranslation();
    const headers = [
        { label: "AGENT IBE #", filter: "ibe", filterable: false },
        { label: "AGENT IBE NAME", filter: "ibeName", filterable: false },
        { label: "DISTRIBUTOR IBE #", filter: "distIbe", filterable: false },
        { label: "DISTRIBUTOR NAME", filter: "distIbeName", filterable: false },
        { label: "AMT ৳", filter: "amountBdt", filterable: false },
        { label: "BALANCE", filter: "balance", filterable: false },
    ];
    const backgroundColor = "#e4f0f2";

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [apiSearchStatus, setApiSearchStatus] = useState(false);
    const [filters, setFilters] = useState({});
    const [files, setFiles] = useState(new Map());

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const {
        register,
        setValue,
        trigger,
        watch,
        setError,
        getValues,
        reset,
        formState: { errors },
    } = useForm();
    const formValues = watch({});

    const handleFormValueChange = (value, type, options = {}) => {
        setValue(`${type}`, value, options);
        setError(`${type}`, { message: "" });
    };

    const agentTransferGet = ApiHook.CallGetAgentEwalletTransfer();
    const agentRequestData = ApiHook.CallgetAgentEwalletTransferReport({
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
    });
    const agentRequestPost = ApiHook.CallPostWalletTransfer({
        setApiCallStatus,
    });
    const agentSearchPost = ApiHook.CallPostAgentSearch();

    const startPage = 1;
    const itemsPerPage = 10;

    const toNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    const handleSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
            agentRequestPost.mutate(formValues, {
                onSuccess: (res) => {
                    if (res?.data?.status) {
                        setApiCallStatus(true);
                        setApiSearchStatus(false);
                        setValue("distIbe", "");
                        setValue("amountBdt", "");
                        setValue("transactionPassword", "");
                    }
                },
            });
        }
    };

    const handleSearch = async () => {
        if (
            formValues?.distIbe?.length > 5 &&
            formValues?.distIbe?.length < 12
        ) {
            agentSearchPost.mutate(formValues?.distIbe, {
                onSuccess: (res) => {
                    if (res.status) {
                        setValue("distUsername", res?.data?.username);
                        setApiSearchStatus(true);
                    } else {
                        setError("distIbe", {
                            message: "Invalid User IBE #",
                        });
                        setApiSearchStatus(false);
                    }
                },
            });
        } else {
            setError("distIbe", { message: "Invalid User IBE #" });
        }
    };

    const exportFunc = () => {
        exportToExcelNew(
            agentRequestData?.data?.data,
            headers,
            "ibe-credit-transfer-report"
        );
    };
    return (
        <>
            <div className="container-bg-design common-div-container">
                <div className="common-div-header gta-b">
                    IBE E-WALLET CREDIT PAYMENT
                </div>
                <div className="credit-request-form-container">
                    <div className="credit-request-form">
                        <CommonInput
                            label="IBE #"
                            placeholder={"0000000000"}
                            backgroundColor={backgroundColor}
                            value={agentTransferGet?.data?.data?.ibeNo}
                            disabled={true}
                        />
                        <CommonInput
                            label="AVAILABLE BALANCE"
                            placeholder={"0000000000"}
                            backgroundColor={backgroundColor}
                            value={agentTransferGet?.data?.data?.balance}
                            disabled={true}
                        />
                        <CommonInput
                            label="DISTRIBUTOR IBE #"
                            placeholder={"Distributor IBE Number"}
                            backgroundColor={backgroundColor}
                            formRegister={register("distIbe", {
                                required: "Distributor IBE number is required",
                            })}
                            value={formValues?.distIbe}
                            onChange={({ target: { value } }) => {
                                if (
                                    isANumber(value) &&
                                    isMaxLength(value, 12)
                                ) {
                                    handleFormValueChange(value, "distIbe");
                                }
                            }}
                        />
                        {errors?.distIbe?.message !== "" && (
                            <span className="error-message-validator">
                                {errors?.distIbe?.message}
                            </span>
                        )}

                        <button
                            className="common-button"
                            onClick={handleSearch}
                        >
                            SEARCH
                        </button>

                        {apiSearchStatus && (
                            <>
                                <CommonInput
                                    label="DISTRIBUTOR USERNAME"
                                    placeholder={"0000000000"}
                                    backgroundColor={backgroundColor}
                                    value={formValues?.distUsername}
                                    disabled={true}
                                />
                                <CommonInput
                                    label="AMOUNT (TAKA)"
                                    type="number"
                                    placeholder={0}
                                    backgroundColor={backgroundColor}
                                    formRegister={register("amountBdt", {
                                        required: "Amount is required",
                                    })}
                                    value={formValues?.amountBdt}
                                    onChange={(e) => {
                                        if (
                                            isANumber(e.target.value) &&
                                            Number(e.target.value) <=
                                                Number(
                                                    agentTransferGet?.data?.data
                                                        ?.balance
                                                )
                                        ) {
                                            handleFormValueChange(
                                                e.target.value,
                                                "amountBdt"
                                            );
                                        }
                                    }}
                                />
                                {errors?.amountBdt?.message !== "" && (
                                    <span className="error-message-validator">
                                        {errors?.amountBdt?.message}
                                    </span>
                                )}

                                <CommonInput
                                    label="TRANSACTION PASSWORD"
                                    type="text"
                                    placeholder={"Your Transaction Password"}
                                    backgroundColor={backgroundColor}
                                    formRegister={register(
                                        "transactionPassword",
                                        {
                                            required:
                                                "Transaction Password is required",
                                        }
                                    )}
                                    value={formValues?.transactionPassword}
                                    onChange={(e) => {
                                        handleFormValueChange(
                                            e.target.value,
                                            "transactionPassword"
                                        );
                                    }}
                                />
                                {errors?.transactionPassword?.message !==
                                    "" && (
                                    <span className="error-message-validator">
                                        {errors?.transactionPassword?.message}
                                    </span>
                                )}
                                <button
                                    className="common-button"
                                    onClick={handleSubmit}
                                >
                                    SUBMIT
                                </button>
                            </>
                        )}
                    </div>
                </div>
                {/* <div className="report-Container"> */}
                <div className="report-Container">
                    <div className="report-table-container-flex">
                        <div
                            className="report-table-container scrollbar-blue"
                            // style={{ display: "flex", flexDirection: "column" }}
                        >
                            <CommonTableComponent
                                headers={headers}
                                datas={agentRequestData?.data?.data}
                                // datas={datas}
                                filters={filters}
                                firstElementSticky={true}
                                searchOption={false}
                                setFilters={setFilters}
                                onSearch={() => {
                                    setApiCallStatus(true);
                                }}
                            />
                        </div>
                    </div>
                    <div className="report-table-container-flex">
                        <div className="pagination-container">
                            {agentRequestData?.data?.data &&
                                agentRequestData?.data?.data?.length !== 0 && (
                                    <TablePagination
                                        startPage={startPage}
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        setCurrentPage={setCurrentPage}
                                        itemsPerPage={itemsPerPage}
                                        toNextPage={toNextPage}
                                        toLastPage={toLastPage}
                                        toPreviousPage={toPreviousPage}
                                        toFirstPage={toFirstPage}
                                        exportButton={true}
                                        exportFunc={exportFunc}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    );
};

export default AgentWalletTransfer;

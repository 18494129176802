import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "react-js-loader";
import { colors } from "@mui/material";

const ProductLists = ({
    products,
    repurchaseType,
    // userCountry = "",
    // activeCurrencies,
    conversionFactor,
    setErrorMessage,
    courierType,
    // setCourierType,
}) => {
    const { t } = useTranslation();
    const [cartProducts, setCartProducts] = useState(products);

    // var conversionFactor =
    //     userCountry.toLowerCase() === "india"
    //         ? {
    //               symbolLeft: activeCurrencies["INR"]?.symbol,
    //               value: activeCurrencies["INR"]?.value,
    //           }
    //         : userCountry.toLowerCase() === "bangladesh"
    //         ? {
    //               symbolLeft: activeCurrencies["BDT"]?.symbol,
    //               value: activeCurrencies["BDT"]?.value,
    //           }
    //         : {
    //               symbolLeft: activeCurrencies["USD"]?.symbol,
    //               value: activeCurrencies["USD"]?.value,
    //           };

    useEffect(() => {
        setCartProducts(products);
    }, [products]);

    const decrementCartMutation = ApiHook.CallDecrementCartItem();
    const addCartMutation = ApiHook.CallAddToCart();

    const handleQuantityChange = (packageId, type, index) => {
        const [mutation, value, defaultValue] =
            type === "plus"
                ? [addCartMutation.mutate, 1, 1]
                : [decrementCartMutation.mutate, -1, 0];

        mutation(
            { packageId: packageId },
            {
                onSuccess: (res) => {
                    if (res.status) {
                        let curentProduct = cartProducts;
                        curentProduct[index]["Cart.quantity"] = curentProduct[
                            index
                        ]["Cart.quantity"]
                            ? curentProduct[index]["Cart.quantity"] + value
                            : defaultValue;

                        setCartProducts(curentProduct);
                    }
                },
            }
        );
    };

    const colorsList = [
        "#E4F0F2",
        "#E5F1DC",
        "#F9F4EF",
        "#d9f9dd",
        "#f8f0dd",
        "#def8fe",
    ];
    return (
        <div className="shoping_page_section" style={{ maxWidth: "1060px" }}>
            <div className="row">
                {cartProducts?.length ? (
                    cartProducts?.map((product, index) => (
                        <div
                            className="col-xxl-4 col-lg-4 col-sm-6 d-md-block d-flex justify-content-center"
                            key={index}
                        >
                            <div className="product_box_container">
                                <div
                                    className="product_box"
                                    style={{
                                        backgroundColor: colorsList[index % 6],
                                    }}
                                >
                                    <div className="product_box_product">
                                        {" "}
                                        <NavLink
                                        // to={`/product-details/${product.id}`}
                                        >
                                            <div className="product_box_image">
                                                <img
                                                    src={
                                                        product.image ??
                                                        "/images/product4.jpg"
                                                    }
                                                    alt="product"
                                                />
                                            </div>
                                        </NavLink>
                                        <div className="product_box_content">
                                            <div className="product_box_head">
                                                {product.name}
                                            </div>
                                            {!!product.fsv &&
                                                product?.fsv !== 0 && (
                                                    <div className="product_box_category">
                                                        {`SV  ${
                                                            product.fsv *
                                                            conversionFactor?.value
                                                        }`}
                                                    </div>
                                                )}
                                            {/* <div className="product_box_amnt">{`${
                                                userSelectedCurrency.symbolLeft
                                            } ${CurrencyConverter(
                                                product.price,
                                                conversionFactor
                                            )}`}</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="product_box_btn_sec">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <p className="product_box_amnt m-0">{`${
                                            conversionFactor.symbolLeft
                                        } ${CurrencyConverter(
                                            product.price,
                                            conversionFactor?.value
                                        )}`}</p>
                                    </div>
                                    <div className="add_cart_button_p">
                                        <div className="product_box_btn2 d-flex">
                                            <div className=" product_box_btn2_text">
                                                {product["Cart.quantity"] ?? 0}
                                            </div>
                                            <div>
                                                <button
                                                    className="product_box_btn2_button"
                                                    disabled={
                                                        addCartMutation.status ===
                                                        "loading"
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleQuantityChange(
                                                            product.id,
                                                            "plus",
                                                            index
                                                        );
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-chevron-up"
                                                        aria-hidden="true"
                                                    ></i>
                                                </button>
                                                <button
                                                    className="product_box_btn2_button"
                                                    disabled={
                                                        decrementCartMutation.status ===
                                                        "loading"
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleQuantityChange(
                                                            product.id,
                                                            "minus",
                                                            index
                                                        );
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-chevron-down"
                                                        aria-hidden="true"
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                        <NavLink
                                            className="product_box_btn2_cart"
                                            to={`/checkout/${repurchaseType}/${courierType}`}
                                            onClick={(e) => {
                                                if (
                                                    repurchaseType === "" ||
                                                    courierType === ""
                                                ) {
                                                    e.preventDefault();
                                                    setErrorMessage({
                                                        courierError:
                                                            courierType === ""
                                                                ? "Courier type is required"
                                                                : "",
                                                        repurchaseError:
                                                            repurchaseType ===
                                                            ""
                                                                ? "Repurchase type is required"
                                                                : "",
                                                    });
                                                }
                                            }}
                                        >
                                            <i
                                                className="fa-solid fa-cart-shopping"
                                                style={{ color: "#fff" }}
                                            ></i>
                                        </NavLink>
                                    </div>

                                    {/* <button
                                        id={`btn-${index}`}
                                        className="product_box_btn1"
                                        onClick={() =>
                                            handleAddToCart(product.id)
                                        }
                                        disabled={addCartMutation.isLoading}
                                    >
                                        {addCartMutation.isLoading &&
                                            cartId === product.id && (
                                                <div
                                                    style={{
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Loader
                                                        type="bubble-top"
                                                        bgColor={"white "}
                                                        size={30}
                                                    />
                                                </div>
                                            )}
                                        <i className="fa fa-cart-shopping"></i>{" "}
                                        {t("add_to_cart")}
                                    </button> */}
                                    {/* <NavLink
                                        to={`/product-details/${product.id}`}
                                        className="product_box_btn2"
                                    >
                                        <i className="fa fa-eye"></i>{" "}
                                        {t("more_details")}
                                    </NavLink> */}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    // placeholder image
                    <div className="newsMainBg">
                        <img src="images/news-no-data.png" alt="" />
                        <div>{t("sorry_no_data_found")}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductLists;

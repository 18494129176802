import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "react-js-loader";
import { colors } from "@mui/material";
import CommonInput from "../../components/Common/CommonInputComponent";

const UpgradeProducts = ({
    setActiveStep,
    products,
    cartData,
    setCartData,
    setTotalAmount,
    setTotalPv,
    totalAmount,
    totalPv,
    userCountry,
    conversionFactor,
    upfsvCriteria,
    handleGstCalc,
    handleShippingChargeCalc,
    courierType,
    setCourierType,
    setOptions1Active,
    options1Active,
    errorsMessage,
    setErrorMessage,
    isBangladesh,
}) => {
    const { t } = useTranslation();
    const [cartProducts, setCartProducts] = useState(products);

    useEffect(() => {
        setCartProducts(products);
        if (isBangladesh) {
            setCourierType("Courier");
        }
    }, [products]);

    const handleQuantityChange = async (product, type) => {
        // const [value, defaultValue] = type === "plus" ? [1, 1] : [-1, 0];
        const { id: packageId, price, mrp, upfsv, weight } = product;
        if (type === "plus") {
            setCartData((cart) => ({
                ...cart,
                [packageId]: {
                    ...product,
                    qty: cart[packageId] ? cart[packageId]?.qty + 1 : 1,
                },
            }));
            setTotalAmount((total) => total + Number(mrp));
            setTotalPv((total) => total + Number(upfsv));
        } else if (
            type === "minus" &&
            cartData[packageId] &&
            cartData[packageId]?.qty > 0
        ) {
            setCartData((cart) => ({
                ...cart,
                [packageId]: {
                    ...product,
                    qty: cart[packageId] ? cart[packageId]?.qty - 1 : 0,
                },
            }));
            let [cumulativeTotalAmount, cumulativeTotalPv] = [
                totalAmount - Number(mrp),
                totalPv - Number(upfsv),
            ];
            setTotalAmount(cumulativeTotalAmount);
            setTotalPv(cumulativeTotalPv);
        }
        await handleGstCalc();
    };
    const gotoCart = async () => {
        if (courierType === "") {
            setErrorMessage({
                courierError: " Please Select Delivery Mode",
            });
        } else {
            if (totalPv < upfsvCriteria) {
                toast.error(`Please add more products .`);
            } else {
                await handleGstCalc();
                // await handleShippingChargeCalc();
                setActiveStep(2);
            }
        }
    };

    const colorsList = [
        "#E4F0F2",
        "#E5F1DC",
        "#F9F4EF",
        "#d9f9dd",
        "#f8f0dd",
        "#def8fe",
    ];
    return (
        <div className="register-cart-container">
            <div className="common-div-header gta-b">
                IBE UPGRADE SIGNUP SHOPPING CART
            </div>
            <div>
                {!isBangladesh && (
                    <div className="repurchase-input-container-flex">
                        <div className="repurchase-input-container px-3">
                            {/* <div
                                className="repurchase-input-div  align-items-center"
                                style={
                                    options1Active
                                        ? {
                                              "--pseudo-element-color": "#fff",
                                              height: "94px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                          }
                                        : {
                                              "--pseudo-element-color": "#fff",
                                              display: "flex",
                                              justifyContent: "space-between",
                                          }
                                }
                            >
                                <div>
                                    {!options1Active && courierType === "" && (
                                        <div
                                            className="repurchase-form-new-input"
                                            style={
                                                options1Active
                                                    ? {
                                                          height: "50%",
                                                      }
                                                    : {}
                                            }
                                            onClick={() => {
                                                setOptions1Active(true);
                                            }}
                                        >
                                            Select Delivery Mode
                                        </div>
                                    )}
                                    {(options1Active ||
                                        courierType === "Courier") && (
                                        <div
                                            className="repurchase-form-new-input"
                                            style={
                                                options1Active
                                                    ? {
                                                          height: "50%",
                                                      }
                                                    : {}
                                            }
                                            onClick={() => {
                                                if (!options1Active) {
                                                    setOptions1Active(true);
                                                } else {
                                                    setCourierType("Courier");
                                                    setOptions1Active(false);
                                                    setErrorMessage(
                                                        (state) => ({
                                                            ...state,
                                                            courierError: "",
                                                        })
                                                    );
                                                }
                                            }}
                                        >
                                            Courier
                                        </div>
                                    )}
                                    {(options1Active ||
                                        courierType === "Selfpickup") && (
                                        <div
                                            className="repurchase-form-new-input"
                                            style={
                                                options1Active
                                                    ? {
                                                          height: "50%",
                                                      }
                                                    : {}
                                            }
                                            onClick={() => {
                                                if (!options1Active) {
                                                    setOptions1Active(true);
                                                } else {
                                                    setCourierType(
                                                        "Selfpickup"
                                                    );
                                                    setOptions1Active(false);
                                                    setErrorMessage(
                                                        (state) => ({
                                                            ...state,
                                                            courierError: "",
                                                        })
                                                    );
                                                }
                                            }}
                                        >
                                            Self pickup
                                        </div>
                                    )}
                                </div>

                                <button
                                    style={{
                                        height: "50px",
                                        width: "50px",
                                        backgroundColor: "transparent",
                                        color: "#9B5731",
                                    }}
                                    onClick={() => {
                                        setOptions1Active((state) => !state);
                                    }}
                                >
                                    {options1Active ? (
                                        <i className="fa-solid fa-caret-up"></i>
                                    ) : (
                                        <i className="fa-solid fa-caret-down"></i>
                                    )}
                                </button>
                            </div> */}
                            <CommonInput
                                type="select"
                                label="Select Delivery Mode"
                                // backgroundColor={"#fff"}
                                value={courierType}
                                onChange={(e) => {
                                    setCourierType(e.target.value);
                                }}
                                options={[
                                    // "Select Delivery Mode",
                                    "Courier",
                                    "Selfpickup",
                                ]}
                                defaultValue=""
                                isLabelDisabled={true}
                            />
                            {errorsMessage?.courierError !== "" && (
                                <span className="error-message-validator">
                                    {errorsMessage?.courierError}
                                </span>
                            )}
                        </div>
                    </div>
                )}
                <div className="shoping_page_section">
                    <div className="row justify-content-center">
                        {cartProducts?.length ? (
                            cartProducts?.map((product, index) => (
                                <div
                                    className="col-xl-3 col-lg-4 col-sm-6 d-md-flex d-flex justify-content-center"
                                    key={index}
                                >
                                    <div className="product_box_container">
                                        <div
                                            className="product_box"
                                            style={{
                                                backgroundColor:
                                                    colorsList[index % 6],
                                            }}
                                        >
                                            <div className="product_box_product">
                                                {" "}
                                                <NavLink>
                                                    <div className="product_box_image">
                                                        <img
                                                            src={
                                                                product.image ??
                                                                "/images/product3.jpg"
                                                            }
                                                            alt="product"
                                                        />
                                                    </div>
                                                </NavLink>
                                                <div className="product_box_content">
                                                    <div className="product_box_head">
                                                        {product.name}
                                                    </div>
                                                    <div className="product_box_category">
                                                        {`SV ${
                                                            conversionFactor?.value *
                                                            product.fsv
                                                        }`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="product_box_btn_sec">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="product_box_amnt m-0">{`${
                                                    conversionFactor.symbolLeft
                                                } ${CurrencyConverter(
                                                    product.mrp,
                                                    conversionFactor?.value
                                                )}`}</p>
                                            </div>
                                            <div className="add_cart_button_p">
                                                <div className="product_box_btn2 d-flex">
                                                    <div className=" product_box_btn2_text">
                                                        {cartData[product.id]
                                                            ?.qty ?? 0}
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="product_box_btn2_button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleQuantityChange(
                                                                    product,
                                                                    "plus"
                                                                );
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-chevron-up"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </button>
                                                        <button
                                                            className="product_box_btn2_button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleQuantityChange(
                                                                    product,
                                                                    "minus"
                                                                );
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-chevron-down"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <button
                                                    className="product_box_btn2_cart"
                                                    onClick={gotoCart}
                                                >
                                                    <i
                                                        className="fa-solid fa-cart-shopping"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // placeholder image
                            <div className="newsMainBg">
                                <img src="images/news-no-data.png" alt="" />
                                <div>{t("sorry_no_data_found")}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradeProducts;

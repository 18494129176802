import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import CommonInput from "../../components/Common/CommonInputComponent";
import {
    AddOrUpdateNominee,
    GetNomineeDetails,
} from "../../services/additonalModules/additionalFeature";
import CustomSelectBox from "../../components/Common/CustomSelectBox";
import {
    isANumber,
    isAlphaOnly,
    isMaxLength,
} from "../../utils/validationHealpers";
import moment from "moment";

const Nominee = () => {
    const { data } = GetNomineeDetails();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [relationship, setRelationship] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [phoneCode, setPhoneCode] = useState("91");
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);

    const [errors, setErrors] = useState({});

    const result = AddOrUpdateNominee();

    const OnNameChange = (e) => {
        setName(e.target.value);
    };

    const onPhoneNumberChange = (value) => {
        setPhone(value);
    };

    const handleSelectRelation = (selectedRelation) => {
        setRelationship(selectedRelation);
    };

    const relationships = [
        "Parent",
        "Sibling",
        "Child",
        "Spouce",
        "Friend",
        "Other",
    ];

    const onDateChange = (newDate) => {
        console.log(newDate, "===========================");
        setSelectedDate(newDate);
    };

    const handleOpenChange = (isOpen) => {
        setIsOpenCalendar(isOpen);
    };

    const handlePhoneCodeChange = (value) => {
        setPhoneCode(value);
    };

    const validation = async () => {
        let isValid = true;
        if (!name.trim()) {
            setErrors((errors) => ({
                ...errors,
                nomineeName: {
                    message: "Nominee Name is Required",
                },
            }));
            isValid = false;
        }

        if (!relationship.trim()) {
            setErrors((errors) => ({
                ...errors,
                relationship: {
                    message: "Nominee Relationship is Required",
                },
            }));
            isValid = false;
        }

        if (!selectedDate.trim()) {
            setErrors((errors) => ({
                ...errors,
                nomineeDob: {
                    message: "Nominee Date of Birth is Required",
                },
            }));
            isValid = false;
        }

        if (!phoneCode.trim() || !phoneCode.trim()) {
            setErrors((errors) => ({
                ...errors,
                mobile: {
                    message: "Nominee Mobile Number is Required",
                },
            }));
            isValid = false;
        }
        if (phone.length < 10) {
            setErrors((errors) => ({
                ...errors,
                mobile: {
                    message: "Invalid mobile number. It should be 10 digits.",
                },
            }));
            isValid = false;
        }

        return isValid;
    };
    const handleSubmit = async () => {
        const isValid = await validation();
        if (!isValid) {
            return;
        }

        let data = {
            name: name,
            relation: relationship,
            dob: selectedDate,
            mobile: phoneCode + "" + -+(phone + ""),
        };

        result.mutate(data, {
            onSuccess: (res) => {
                if (res.status) {
                    toast.success("Nominee updated successfully!");
                } else {
                    toast.error("Error updating nominee!");
                }
            },
        });
    };

    useEffect(() => {
        setPhone(data?.mobile?.split("-")[1] || "");
        setPhoneCode(data?.mobile?.split("-")[0] || 91);
        setName(data?.name || "");
        setSelectedDate(moment(data?.dob).format("YYYY-MM-DD"));
        setRelationship(data?.relation ?? "");
    }, [data]);

    const maxDate = moment().format("YYYY-MM-DD");

    return (
        <div className="nominee-parent container-bg-design common-div-container">
            <div className="nominee-content">
                <h1
                    style={{ margin: "6vh 0 8vh 0" }}
                    className="bebas-neue-regular"
                >
                    NOMINEE DETAILS
                </h1>

                <CommonInput
                    label={"NOMINEE NAME"}
                    placeholder={"Nominee name"}
                    onChange={(e) => {
                        if (
                            isAlphaOnly(e.target.value) &&
                            isMaxLength(e.target.value, 25)
                        ) {
                            OnNameChange(e);
                            setErrors((errors) => ({
                                ...errors,
                                nomineeName: {
                                    message: "",
                                },
                            }));
                        }
                    }}
                    value={name}
                    backgroundColor="#e4f0f2"
                />

                {errors?.nomineeName?.message !== "" && (
                    <span className="error-message-validator w-100Perc">
                        {errors?.nomineeName?.message}
                    </span>
                )}

                <CommonInput
                    label="NOMINEE RELATIONSHIP"
                    type="select"
                    value={relationship}
                    placeHolder={"Select Relationship"}
                    options={relationships || []}
                    onChange={(e) => {
                        handleSelectRelation(e.target.value);
                        setErrors((errors) => ({
                            ...errors,
                            relationship: {
                                message: "",
                            },
                        }));
                    }}
                    backgroundColor="#e4f0f2"
                />

                {errors?.relationship?.message !== "" && (
                    <span className="error-message-validator w-100Perc">
                        {errors?.relationship?.message}
                    </span>
                )}

                <CommonInput
                    type="date"
                    label="NOMINEE DATE OF BIRTH"
                    placeholder="Select..."
                    backgroundColor={"#e4f0f2"}
                    value={selectedDate}
                    onChange={(e) => {
                        onDateChange(e.target.value);
                        setErrors((errors) => ({
                            ...errors,
                            nomineeDob: {
                                message: "",
                            },
                        }));
                    }}
                    defaultValue=""
                    max={maxDate}
                />

                {errors?.nomineeDob?.message !== "" && (
                    <span className="error-message-validator w-100Perc">
                        {errors?.nomineeDob?.message}
                    </span>
                )}

                <CommonInput
                    type="mobile"
                    label="NOMINEE MOBILE NUMBER"
                    placeholder={"0000000000000"}
                    backgroundColor={"#e4f0f2"}
                    value={phone}
                    onChange={(e) => {
                        if (
                            isMaxLength(e.target.value, 10) &&
                            isANumber(e.target.value)
                        ) {
                            onPhoneNumberChange(e.target.value);
                            setErrors((errors) => ({
                                ...errors,
                                mobile: {
                                    message: "",
                                },
                            }));
                        }
                    }}
                    options={["1", "91", "880"]}
                    phoneCodeChange={(e) => {
                        handlePhoneCodeChange(e.target.value);
                    }}
                    phoneCode={phoneCode}
                />

                {errors?.mobile?.message !== "" && (
                    <span className="error-message-validator w-100Perc">
                        {errors?.mobile?.message}
                    </span>
                )}

                <button className="common-button" onClick={handleSubmit}>
                    <span style={{ fontWeight: 700, color: "white" }}>
                        SUBMIT
                    </span>
                </button>
            </div>
        </div>
    );
};

export default Nominee;
